import React from "react";

import "../css/App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { faCheckSquare, faCoffee } from "@fortawesome/free-solid-svg-icons";

import { Route, Switch } from "react-router-dom";

//doctors
import Karpyshyna from "../../src/components/doctors/Karpyshuna";
import Karpyshyn from "../../src/components/doctors/Karpyshun";
import Shybin from "../../src/components/doctors/Shybin";
import Tovshik from "../../src/components/doctors/Tovshik";
import Popovska from "../../src/components/doctors/Popovska";
import Miheeva from "../../src/components/doctors/Miheeva";
import Pulupchyk from "../../src/components/doctors/Pulupchyk"
import pokrasova from "../../src/components/doctors/pokrasova"
import Mastimenko from "./doctors/Mastimenko";
import Shyanova from "./doctors/Shyanova";

import Home from "./Home";
import About from "./about";
import Contacts from "./contacts";
import Reviews from "./reviews";
import News from "./news";
import Doctors from "./doctors";
import Prices from "./prices";
import Reproductive from "./reproductive";

class App extends React.Component {
  render() {
    return (
      <main>
        <Switch>
          <Route path="/" component={Home} exact />
          <Route path="/reproductive" component={Reproductive} exact />
          <Route path="/about" component={About} />
          <Route path="/contacts" component={Contacts} />
          <Route path="/doctors" component={Doctors} />
          <Route path="/prices" component={Prices} />
          <Route path="/reviews" component={Reviews} />
          <Route path="/news" component={News} />
          {/* <Route path="/test" component={Test} /> */}
          {/* doctors */}
          <Route path="/karpyshyna" component={Karpyshyna} />
          <Route path="/karpyshyn" component={Karpyshyn} />
          <Route path="/shybin" component={Shybin} />
          <Route path="/tovshik" component={Tovshik} />
          <Route path="/popovska" component={Popovska} />
          <Route path="/miheeva" component={Miheeva} />
          <Route path="/pulupchyk" component={Pulupchyk} />
          <Route path="/pokrasova" component={pokrasova} />
          <Route path="/mastimenko" component={Mastimenko} />
          <Route path="/shyanova" component={Shyanova} />
        </Switch>
      </main>
    );
  }
}
library.add(fab, faCheckSquare, faCoffee);

export default App;
