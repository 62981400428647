import React from "react";

import Header from "../Header";
import Footer from "../Footer";

import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";

import "../../css/Doctors.css";

// import Card from "react-bootstrap/Card";
// import CardDeck from "react-bootstrap/CardDeck";
// import CardGroup from "react-bootstrap/CardGroup";

import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import ListGroup from "react-bootstrap/ListGroup";

class Shyanova extends React.Component {
  render() {
    return (

      <Container fluid style={{ marginTop: "5%" }}>
        <Header />
        {/* <NavBar /> */}
        <div className="row">
          <p className="text-left h2 top-h ml-3">Шиянова Світлана Володимирівна</p>
        </div>
        <div className="row" style={{ paddingBottom: "10%" }}>
          {/* <Col xs={12} md={12} lg={6} className="padding">
            <Image
              src={require("../../assets/karpyshun_info.png")}
              fluid
              className="w-100 top"
            />
          </Col> */}
          <Col xs={12} md={12} lg={12}>
            <p className="text-center fontSize25" style={{ paddingTop: 25 }}>
              Експерт - репродуктолог
              <br />
              Загальний стаж роботи лікарем 20 років.
            </p>
            <p
              className="text-left"
              style={{ fontSize: 20, fontWeight: 100 }}
            >
              <p
                className="text-justify bottom doctorsFont"
                style={{ fontSize: 15.8, fontWeight: 100 }}
              >
                Закінчила у 2000 році Харківський державний медичний університет. Кандидат медичних наук за спеціальністю «Акушерство та гінекологія» тема дисертації "Тактика допоміжних репродуктивних технологій у жінок з вродженими аномаліями розвитку матки". 2019-2020 рр. - лектор курсів ТУ «Основи Допоміжних Репродуктивних технологій (ДРТ)», «Професійний ДРТ», «Звичні втрати вагітності» та майстер-класу «Індукція овуляції», «Сучасні можливості відновлення фертильності при синдромі Ашермана». Учасниця майстер-класів спільно з видавництвом «EXTEMPORE» журналу «З турботою про жінку»: «Невиношування вагітності», «Репродуктологія: зрозуміло про складне», «Діагностика вроджених аномалій матки», «Імплантація, фертильні розлади та акушерські негаразди». Член в асоціаціях та товариствах, постійно бере участь у міжнародних та українських науково-практичних конференціях, форумах та семінарах, зокрема:
              </p>
              <Accordion defaultActiveKey="1" className="border">
                <Card>
                  <Accordion.Toggle as={Card.Header} eventKey="0">
                    Заслуги
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey="0">
                    <Card.Body>
                      <ListGroup style={{ fontSize: 15 }}>
                        <ListGroup.Item>
                          • ESHRE - European Society Human Reproduction and Embriology;
                        </ListGroup.Item>
                        <ListGroup.Item>
                          • УАРМ - Українська Асоціація Репродуктивної Медицини;
                        </ListGroup.Item>
                        <ListGroup.Item>
                          • 2020 р. -  Науково-практична конференція Virtual «Східноєвропейські зустрічі професіоналів: звичні втрати вагітності» - доповідь «Хронічний ендометрит - стара проблема, нові уявлення та майбутні виклики»; SynevoDay «Лабораторна діагностика – стрибок у майбутнє»; 1-st IVF Worldwide Online Congress in reproductive Medicine;  ESHRE Virtual 36-th Annual Meeting; 1-st IVF Worldwide Online Congress in reproductive Medicine; 3-rd IVF Worldwide Online Congress in reproductive Medicine; Annual Meeting.тESHRE Virtual 37-th;
                        </ListGroup.Item>
                        <ListGroup.Item>
                          • 2021 р.  – Фахова тематична школа GYN: онлайн-практикум сучасного акушера-гінеколога «Секрети майстерності від авторитетів та їх учнів» - доповідь «Мікробіом ендометрія та репродуктивний прогноз – сьогодення чи майбутнє»;
                        </ListGroup.Item>

                      </ListGroup>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              </Accordion>
            </p>
          </Col>
        </div>
        <Footer />
      </Container>


    );
  }
}

export default Shyanova;
