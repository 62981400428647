import React from "react";

import Header from "../Header";
import Footer from "../Footer";

import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";

import "../../css/Doctors.css";

// import Card from "react-bootstrap/Card";
// import CardDeck from "react-bootstrap/CardDeck";
// import CardGroup from "react-bootstrap/CardGroup";

import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import ListGroup from "react-bootstrap/ListGroup";

class Tovshik extends React.Component {
  render() {
    return (
      <Container fluid style={{ marginTop: "5%" }}>
        <Header />
        {/* <NavBar /> */}
        <div className="row">
          <p className="text-left h2 top-h ml-3">Товщик Наталія Вікторівна</p>
        </div>
        <div className="row" style={{ paddingBottom: "13%" }}>
          {/* <Col xs={12} md={12} lg={6} className="padding">
            <Image
              src={require("../../assets/karpyshun_info.png")}
              fluid
              className="w-100 top"
            />
          </Col> */}
          <Col xs={12} md={12} lg={6}>
            <p className="text-center fontSize25" style={{ paddingTop: 25 }}>
              Лікар функціональної та УЗД діагностики першої кваліфікаційної
              категорії.
              <br />
              Загальний стаж роботи лікарем 21 рік.
            </p>
            <p className="text-left" style={{ fontSize: 20, fontWeight: 100 }}>
              <p
                className="text-justify bottom doctorsFont"
                style={{ fontSize: 15.8, fontWeight: 100 }}
              >
                У 1998р. закінчила Тернопільську Державну Медичну Академію ім
                І.Я.Горбачевського. 1998р.-2000р. – проходила інтернатуру в
                Київській Національній медичній академії післядипломної освіти
                ім П.Л.Шупика. 2007р. - закінчила спеціалізацію з УЗД
                діагностики в Київській Національній академії післядипломної
                освіти ім П.Л. Шупика. 2008р.-2019р. – лікар функціональної та
                ультразвукової діагностики Житомирської обласної лікарні ім О.Ф.
                Горбачевського. Приймає активну участь в науково-практичних
                конференціях, зокрема:
              </p>
              <Accordion defaultActiveKey="1" className="border">
                <Card>
                  <Accordion.Toggle as={Card.Header} eventKey="0">
                    Заслуги
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey="0">
                    <Card.Body>
                      <ListGroup style={{ fontSize: 15 }}>
                        <ListGroup.Item>
                          • Міжнародний науково-практичний конгрес "З
                          ультразвуку в медицині", м. Київ, 2018 р.;
                        </ListGroup.Item>
                        <ListGroup.Item>
                          • Навчально-практичний курс в центрі високих
                          ультразвукових технологій Українського Допплерівського
                          Клубу "УЗД брахіоцефальних артерій та
                          ехокардіографії", м. Київ, 2018 р.
                        </ListGroup.Item>
                        <ListGroup.Item>
                          • Навчання на курсі стажування та інформації за темою
                          "Ультразвукова діагностика в травматології та
                          ортопедії", м. Київ, 2017 р.
                        </ListGroup.Item>
                        <ListGroup.Item>
                          • Навчання на курсі стажування та інформації за темою
                          "Ультразвукова діагностика в маммології", м. Київ,
                          2016 р.
                        </ListGroup.Item>
                        <ListGroup.Item>
                          • Курс інформації та стажування в навчальному центрі
                          високих ультразвукових технологій Українського
                          Допплерівського Клубу "Актуальні питання сучасної
                          єхокардіографії", м. Київ, 2016 р.
                        </ListGroup.Item>
                        <ListGroup.Item>
                          • XI науково-практична конференція з міжнародною
                          участю "Практичне значення нових технологій
                          ультразвукової діагностики захворювань серця, судин,
                          щитоподібної залози та шлунково-кишкового тракту", м.
                          Київ, 2015 р.
                        </ListGroup.Item>
                        <ListGroup.Item>
                          Семінар в тренінг-центрі "TOSHIBA" за темою:
                          "Ультразвукова діагностика захворювань підшлункової
                          залози", м. Житомир, 2015 р.
                        </ListGroup.Item>
                        <ListGroup.Item>
                          • Курс інформації та стажування в навчальному центрі
                          високих ультразвукових технологій Українського
                          Допплерівського Клубу "Актуальні питання
                          ультразвукової цереброангіології", м. Київ, 2018 р.
                        </ListGroup.Item>
                      </ListGroup>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              </Accordion>
            </p>
            {/* <p className="text-center fontSize25">
              Лікар функціональної та УЗД діагностики першої кваліфікаційної
              категорії.
              <br />
              Загальний стаж роботи лікарем 21 рік.
            </p> */}
            {/* 22.5 25  */}
            {/* <p className="text-center fontSize225">
              Закінчив Вінницький медінститут ім. М.І.Пирогова.У 2018 р. отримав
              сертифікат лікаря-спеціаліста за спеціальністю Організація і
              управління охороною здоров'я.
            </p> */}
          </Col>
        </div>
        <Footer />
      </Container>
    );
  }
}

export default Tovshik;
