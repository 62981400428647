import React from "react";

import Header from "../Header";
import Footer from "../Footer";
import NavBar from "../NavBar";

import { Link } from "react-router-dom";
import "../../css/DoctorsPage.css";

import Card from "react-bootstrap/Card";
import Container from "react-bootstrap/Container";

class Doctors extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}
  render() {
    return (
      <Container fluid style={{ marginTop: "6%" }}>
        <Header />
        <NavBar />
        <div
          className="d-flex justify-content-around flex-wrap "
          style={{
            padding: 30,
            paddingLeft: 10,
            paddingRight: 10,
            marginLeft: 20,
          }}
        >
          <Card className="width">
            <Card.Img
              variant="top"
              src={require("../../assets/karpyshyna.png")}
            />
            <Card.Body>
              <Card.Title>Карпишина Лілія Володимирівна</Card.Title>
              <Card.Text>
                Провідний спеціаліст центру сімейного здоров’я "ЛІАМЕД",
                лікар-акушер-гінеколог вищої категорії. Загальний стаж роботи
                лікарем 28 років.
              </Card.Text>
            </Card.Body>
            <Card.Footer>
              <Link to="/karpyshyna" target="_blank">
                Детальніше
              </Link>
            </Card.Footer>
          </Card>
          <Card className="width">
            <Card.Img variant="top" src={require("../../assets/shubin.jpg")} />
            <Card.Body>
              <Card.Title>Шубін Валерій Дмитрович</Card.Title>
              <Card.Text>
                Лікар вищої кваліфікаційної категорії по карідології та терапії.
                Загальний стаж роботи лікарем 43 роки.
              </Card.Text>
            </Card.Body>
            <Card.Footer>
              <Link to="/shybin" target="_blank">
                Детальніше
              </Link>
            </Card.Footer>
          </Card>
          <Card className="width">
            <Card.Img variant="top" src={require("../../assets/pulupchyk.jpg")} />
            <Card.Body>
              <Card.Title>Пилипчук Людмила Володимирівна</Card.Title>
              <Card.Text>
                Лікар-ендокринолог вищої кваліфікаційної категорії Загальний
                стаж роботи 23 роки.
              </Card.Text>
            </Card.Body>
            <Card.Footer>
              <Link to="/pulupchyk" target="_blank">
                Детальніше
              </Link>
            </Card.Footer>
          </Card>
          <Card className="width">
            <Card.Img variant="top" src={require("../../assets/Pokrasova.jpg")} />
            <Card.Body>
              <Card.Title>ПОКРАСОВА СВІТЛАНА АНАТОЛІЇВНА</Card.Title>
              <Card.Text>
              Лікар генетик. Стаж роботи за фахом - 16 років.
              </Card.Text>
            </Card.Body>
            <Card.Footer>
              <Link to="/pokrasova" target="_blank">
                Детальніше
              </Link>
            </Card.Footer>
          </Card>
          {/* </div>
        <div
          className="d-flex justify-content-around flex-wrap "
          style={{
            padding: 30,
            paddingLeft: 10,
            paddingRight: 10,
            marginLeft: 20,
          }}
        > */}
          <Card className="width">
            <Card.Img
              variant="top"
              src={require("../../assets/popovish.jpg")}
            />
            <Card.Body>
              <Card.Title>Поповська Алла Анатоліївна</Card.Title>
              <Card.Text>
                Лікар акушер-гінеколог 1 кваліфікаційної категорії, лікар УЗД.
                Загальний лікарський стаж — 12 років.
              </Card.Text>
            </Card.Body>
            <Card.Footer>
              <Link to="/popovska" target="_blank">
                Детальніше
              </Link>
            </Card.Footer>
          </Card>
          {/* <Card className="width">
            <Card.Img variant="top" src={require("../../assets/miheeva.jpg")} />
            <Card.Body>
              <Card.Title>Міхєєва Світлана Юріївна</Card.Title>
              <Card.Text>
                Лікар — невропатолог вищої категорії. Загальний стаж роботи 37
                років.
              </Card.Text>
            </Card.Body>
            <Card.Footer>
              <Link to="/miheeva" target="_blank">
                Детальніше
              </Link>
            </Card.Footer>
          </Card> */}
          <Card className="width">
            <Card.Img
              variant="top"
              src={require("../../assets/karpyshyn.png")}
            />
            <Card.Body>
              <Card.Title>Карпишин Анатолій Олександрович</Card.Title>
              <Card.Text>
                Медичний директор центру сімейного здоров’я “ЛІАМЕД”, лікар
                загальної практики вищої категорії. Загальний стаж роботи 28
                років.
              </Card.Text>
            </Card.Body>
            <Card.Footer>
              <Link to="/karpyshyn" target="_blank">
                Детальніше
              </Link>
            </Card.Footer>
          </Card>
        </div>
        <Footer />
      </Container>
    );
  }
}

export default Doctors;
