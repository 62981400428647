export const _1 = [
  { text: "УЗД черевної порожнини та нирок", price: 500 },
  {
    text: "УЗД щитовидної залози",
    price: 400,
  },
  {
    text: "УЗД простати та сечового міхура",
    price: 500,
  },
  {
    text: "УЗД динаміка",
    price: 320,
  },
  {
    text: "УЗД гінекологічне трансвагінальне",
    price: 400,
  },
  {
    text: "УЗД гінекологічне трансабдомінальне",
    price: 350,
  },
  {
    text: "УЗД молочних залоз",
    price: 450,
  },
  {
    text: "УЗД 1-го суглоба",
    price: 340,
  },
  {
    text: "УЗД 2-х суглобів",
    price: 420,
  },
  {
    text:
      "УЗД грудної порожнини ( плевральна порожнина з визначенням рівня рідини)",
    price: 300,
  },
  {
    text: "УЗД калитки ( рус – мошонки) і визначення ступенів варікоцеле",
    price: 400,
  },
  {
    text: "УЗД м’яких тканин ( шкіра, п/ш клітковина, л/вузли, сухожилля)",
    price: 400,
  },
  {
    text: "УЗД нирок",
    price: 400,
  },
  {
    text: "УЗД нирок + сечового міхура",
    price: 500,
  },
  {
    text: "УЗД органів черевної порожнини",
    price: 400,
  },
  {
    text: "УЗД простати",
    price: 400,
  },
  {
    text: "УЗД простати і сечового міхура",
    price: 500,
  },
  {
    text: "УЗД сечового міхура",
    price: 400,
  },
  {
    text: "УЗД серця(ехокардіографія)",
    price: 600,
  },
  {
    text: "УЗД слинних залоз",
    price: 300,
  },
  {
    text: "УЗД артерій і вен верхніх кінцівок",
    price: 550,
  },
  {
    text: "УЗД артерій і вен нижніх кінцівок",
    price: 500,
  },
  {
    text: "УЗД вен верхніх кінцівок",
    price: 550,
  },
  {
    text: "УЗД вен нижніх кінцівок",
    price: 550,
  },
  {
    text: "УЗД артерій верхніх кінцівок",
    price: 550,
  },
  {
    text: "УЗД артерій нижніх кінцівок",
    price: 550,
  },
  {
    text: "УЗД судин голови та шиї",
    price: 550,
  },
  {
    text: "Кольпоскопія (з відеозображенням)",
    price: 600,
  },
  {
    text: "Взяття крові одноразовими системами",
    price: 60,
  },
  {
    text: "Взяття зразків у транспортний контейнер",
    price: 60,
  },
  {
    text: "Взяття слини у транспортний контейнер Salivette",
    price: 60,
  },
  {
    text: "Взяття зіскрібка у фіксуючий розчин",
    price: 60,
  },
  {
    text: "Взяття зразків з урогенітального тракту одноразовими системами",
    price: 60,
  },
  {
    text: "Взяття середовище Кері-Блейра",
    price: 60,
  },
  {
    text: "Взяття DUO/сечі на бакпосів у транспортний контейнер",
    price: 60,
  },
  {
    text: "Електрокардіографія",
    price: 95,
  },

];
