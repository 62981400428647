import React from "react";

import Header from "../Header";
import Footer from "../Footer";

import AboutSlider from "../AboutSlider";

import Image from "react-bootstrap/Image";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";

import "../../css/About.css";

class Clinics extends React.Component {
  render() {
    return (
      <Container fluid style={{ marginTop: "6%" }}>
        <Header />
        <div className="row health">
          <p id="aboutHeader" className="text-center">
            Довірте здоров’я вашої сім’ї – найсильнішим!
          </p>
        </div>
        <div className="row">
          <Col xs={12} md={12} lg={6} className="padding text-center">
            <Image src={require("../../assets/about_girls.jpg")} fluid />
          </Col>
          <Col xs={12} md={12} lg={6}>
            <p
              className="text-left padding"
              style={{ fontSize: 20, fontWeight: 100 }}
            >
              <div className="block-font">
                &emsp;
                <span className="text-green pin-social">
                  Медичний центр “ЛІАМЕД”
                </span>{" "}
                – це спеціалізований медичний центр експертного рівня. В активі
                наших спеціалістів: значний лікарський досвід, тисячі успішних
                амбулаторних процедур, збережене та відновлене здоров’я
                пацієнтів.
                <br />
                <br />
              </div>
              <div className="block-font">
                &emsp;<span className="text-green">“ЛІАМЕД”</span> активно
                співпрацює з провідними вітчизняними та міжнародними фахівцями в
                сфері медицини, бере участь в науково-практичних конференціях, а
                також оснащена найсучаснішим медичним обладнанням, яке дозволяє
                проводити спеціалізовані медичні процедури на найвищому рівні.
                <br />
                <br />
              </div>
              <div className="block-font">
                &emsp;<span className="text-green">ЛІКАРІ “ЛІАМЕД”</span> – це
                єдина медична команда: фахівці з багаторічним досвідом роботи,
                лідери думки в питаннях жіночого здоров’я, експерти
                акушер-гінекології національного рівня. Професіонали нашої
                клініки, поряд з досвідом, мають бездоганну репутацію, активно
                розвиваються та удосконалюються у медичній сфері, беручи на
                озброєння передові медичні технології.
              </div>
              <br />

              <div className="block-font">
                &emsp;<span className="text-green">МІСІЯ:</span> Здоров’я – це
                фундамент нашого щастя та успіху, а якість життя – це те, що має
                дарувати посмішку щодня. Команда лікарів, яка втілює досвід
                кращих сучасних тенденцій в медицині та готова чути свого
                пацієнта.'
              </div>
            </p>
          </Col>
        </div>

        <AboutSlider />
        {/* </Container> */}
        <Footer />
      </Container>
    );
  }
}

export default Clinics;
