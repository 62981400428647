import React from "react";

import Header from "../Header";
import Footer from "../Footer";

import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";

import "../../css/Doctors.css";

import Image from "react-bootstrap/Image";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import ListGroup from "react-bootstrap/ListGroup";

class Popovska extends React.Component {
  render() {
    return (
      <Container fluid style={{ marginTop: "5%" }}>
        <Header />
        {/* <NavBar /> */}
        <div className="row">
          <p className="text-left h2 top-h ml-3">
            Пилипчук Людмила Володимирівна
          </p>
        </div>
        <div className="row">
          <Col xs={12} md={12} lg={6} className="padding">
            {/* <Image
              src={require("../../assets/pariy.jpg")}
              fluid
              className="w-100 top"
            /> */}
          </Col>
          <Col xs={12} md={12} lg={6}>
            <p className="text-center fontSize25" style={{ paddingTop: 25 }}>
              Лікар-ендокринолог вищої кваліфікаційної категорії Загальний
              <br />
              стаж роботи 22 роки.
            </p>
            <p className="text-left" style={{ fontSize: 20, fontWeight: 100 }}>
              <p
                className="text-justify bottom doctorsFont"
                style={{ fontSize: 15.8, fontWeight: 100 }}
              >
                1997 р. - закінчила Львівський медичний університет за
                спеціальністю «Лікувальна справа»,; 1997-1998 - спеціалізація за
                фахом «Ендокринологія» при Національній медичній академії
                післядипломної освіти імені П. Л. Шупика. 2000 – 2019 -
                лікар-ендокринолог поліклініки КУ ЦМЛ №1, м.Житомир.; Член
                Асоціації ендокринологів України, член «Клубу ендокринологічних
                новацій», член Асоціації превентивної та антиейджинг медицини.
                Пріоритетні напрямки діяльності: - діагностика та лікування
                захворювань щитоподібної залози та патологія
                гіпоталамо-гіпофізарної системи; - терапія надмірної ваги,
                ендокринних порушень, ендокринного непліддя та клімактеричних
                розладів; - діагностика та лікування діабету та його ускладнень,
                діабетологія; - ендокринологія вагітності, ендокринологічні
                аспекти реалізації допоміжних репродуктивних технологій у
                лікуванні непліддя.
              </p>
              <Accordion defaultActiveKey="1" className="border">
                <Card>
                  <Accordion.Toggle as={Card.Header} eventKey="0">
                    Заслуги
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey="0">
                    <Card.Body>
                      <ListGroup style={{ fontSize: 15 }}>
                        <ListGroup.Item>
                          • Участь в тренінгу «Коучинг здоров’я як інструмент
                          діалогу та партнерства з людьми з діабетом різних
                          психологічних характерологій»2014р
                        </ListGroup.Item>
                        <ListGroup.Item>
                          • «Сучасні підходи до лікування діабетичної
                          полінейропатії», 2016 р.
                        </ListGroup.Item>
                        <ListGroup.Item>
                          • Український навчальний курс Європейської асоціації з
                          вивчення цукрового діабету «Актуальні питання сучасної
                          діабетології» (2015 р., 2017 р., 2019 р.)
                        </ListGroup.Item>
                        <ListGroup.Item>
                          • Науково-практична конференція в рамках освітнього
                          проекту «Школа ендокринолога» ( 2017 р., 2018 р., 2019
                          р.)
                        </ListGroup.Item>
                        <ListGroup.Item>
                          • Науково-практична конференція в рамках проекту «Клуб
                          ендокринологічних новацій» (2018 р., 2019 р.).
                        </ListGroup.Item>
                        <ListGroup.Item>
                          • Науково-практична конференція «Сучасні аспекти
                          раціонального харчування»
                        </ListGroup.Item>
                      </ListGroup>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              </Accordion>
            </p>
            {/* <p className="text-center fontSize25">
              Лікар акушер-гінеколог 2 кваліфікаційної категорії, лікар УЗД.
              <br />
              Загальний лікарський стаж — 11 років.
            </p> */}
            {/* 22.5 25  */}
            {/* <p className="text-center fontSize225">
              Закінчив Вінницький медінститут ім. М.І.Пирогова.У 2018 р. отримав
              сертифікат лікаря-спеціаліста за спеціальністю Організація і
              управління охороною здоров'я.
            </p> */}
          </Col>
        </div>
        <Footer />
      </Container>
    );
  }
}

export default Popovska;
