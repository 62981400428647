import React from "react";

import Image from "react-bootstrap/Image";

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};
class AboutSlider extends React.Component {
  render() {
    return (
      <Carousel responsive={responsive} keyBoardControl={true} showDots={true}>
        <Image
          src={require("../assets/2_girls.jpg")}
          rounded
          fluid
          className="w-100"
        />
        <Image
          src={require("../assets/about_girls.jpg")}
          rounded
          fluid
          className="w-100"
        />
        <Image
          src={require("../assets/advice_girl.jpg")}
          rounded
          fluid
          className="w-100"
        />
        <Image
          src={require("../assets/advice_girls.jpg")}
          rounded
          fluid
          className="w-100"
        />
        <Image
          src={require("../assets/doctor_girl.jpg")}
          rounded
          fluid
          className="w-100"
        />
        <Image
          src={require("../assets/facilities.jpg")}
          rounded
          fluid
          className="w-100"
        />
        <Image
          src={require("../assets/man_woman.jpg")}
          rounded
          fluid
          className="w-100"
        />
        <Image
          src={require("../assets/man.jpg")}
          rounded
          fluid
          className="w-100"
        />
        <Image
          src={require("../assets/medal.jpg")}
          rounded
          fluid
          className="w-100"
        />
        <Image
          src={require("../assets/purple_girl.jpg")}
          rounded
          fluid
          className="w-100"
        />
        <Image
          src={require("../assets/purple_white.jpg")}
          rounded
          fluid
          className="w-100"
        />
        <Image
          src={require("../assets/reading_book.jpg")}
          rounded
          fluid
          className="w-100"
        />
        <Image
          src={require("../assets/smiling_brunet.jpg")}
          rounded
          fluid
          className="w-100"
        />
        <Image
          src={require("../assets/smiling.jpg")}
          rounded
          fluid
          className="w-100"
        />
        <Image
          src={require("../assets/team2.jpg")}
          rounded
          fluid
          className="w-100"
        />
        <Image
          src={require("../assets/team.jpg")}
          rounded
          fluid
          className="w-100"
        />
      </Carousel>
    );
  }
}
export default AboutSlider;
