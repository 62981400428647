import React from "react";

import "../css/App.css";
import "bootstrap/dist/css/bootstrap.min.css";

import Container from "react-bootstrap/Container";

import Header from "./Header";
import Footer from "./Footer";
//import Slider from "./BootstrapSlider";
// Changed slider to image in Content

import Content from "./home/components/content";

class Home extends React.Component {
  render() {
    return (
      <Container fluid style={{ marginTop: "6%" }}>
        <Header />
        {/* <Slider /> */}
        <Content />
        <Footer />
      </Container>
    );
  }
}

export default Home;
