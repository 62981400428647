import React from "react";

import Header from "../Header";
import Footer from "../Footer";
import NavBar from "../NavBar";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import ReactPlayer from "react-player";
import Image from "react-bootstrap/Image";
import Container from "react-bootstrap/Container";
import Card from "react-bootstrap/Card";

class News extends React.Component {
  render() {
    return (
      <Container fluid style={{ marginTop: "6%" }}>
        <Header />
        <NavBar />
        <Row style={{ padding: 30 }} className="border-bottom">
          <Col xs={{ span: 12 }} md={{ span: 4 }} lg={{ span: 3 }}>
            <Image
              src={require("../../assets/news-2.jpg")}
              className="w-100"
              style={{ borderRadius: 10 }}
            />
            {/* <a href="https://www.facebook.com/liamed.zt/videos/926670697764597"> */}
          </Col>
          <Col xs={{ span: 12 }} md={{ span: 6 }} lg={{ span: 9 }}>
            <Card className="border-0">
              <Card.Header as="h5" style={{ backgroundColor: "#e8e9ed" }}>
                Вітаємо з Великоднем!
              </Card.Header>
              <Card.Body>
                <Card.Title style={{ fontSize: 16 }}>
                  В це чудове свято хочу побажати йти по життю з доброю мрією,
                  високими прагненнями, щирою надією, міцною вірою і великою
                  любов’ю. Нехай ніжний аромат здобних пасок, яскраві фарби
                  яєць, дзвінкий сміх і світлі посмішки близьких наповнять цей
                  день справжнім дивом і прекрасним настроєм.
                </Card.Title>
                <Card.Text
                  style={{ fontSize: 14, marginTop: 5, color: "grey" }}
                  className="text-right"
                >
                  19 Квітня 2020
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row style={{ padding: 30 }} className="border-bottom">
          <Col xs={{ span: 12 }} md={{ span: 4 }} lg={{ span: 3 }}>
            {/* <Image
              src={require("../../assets/test.jpg")}
              className="w-100"
              style={{ borderRadius: 10 }}
            /> */}
            <ReactPlayer
              url="https://www.facebook.com/liamed.zt/videos/926670697764597"
              className="w-100"
              width="100%"
              height="100%"
              // style={{ padding: 0, margin: 0 }}
              // playing
            />
          </Col>
          <Col xs={{ span: 12 }} md={{ span: 6 }} lg={{ span: 9 }}>
            <Card className="border-0">
              <Card.Header as="h5" style={{ backgroundColor: "#e8e9ed" }}>
                Вплив коронастресу на здоров'я жінки.
              </Card.Header>
              <Card.Body>
                <Card.Title style={{ fontSize: 16 }}>
                  Олег Чабан <br></br>
                  Національний медичний університет ім. О.О. Богомольця
                </Card.Title>
                <Card.Text
                  style={{ fontSize: 14, marginTop: 5, color: "grey" }}
                  className="text-right"
                >
                  8 Квітня 2020
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row style={{ padding: 30 }}>
          <Col xs={{ span: 12 }} md={{ span: 4 }} lg={{ span: 3 }}>
            <Image
              src={require("../../assets/news-3.jpg")}
              className="w-100"
              style={{ borderRadius: 10 }}
            />
          </Col>
          <Col xs={{ span: 12 }} md={{ span: 6 }} lg={{ span: 9 }}>
            <Card className="border-0">
              <Card.Header as="h5" style={{ backgroundColor: "#e8e9ed" }}>
                Жінка 40+: Не ставте життя на паузу (ONLINE)
              </Card.Header>
              <Card.Body>
                <Card.Title style={{ fontSize: 16 }}>
                  У багатьох жінок віковий період менопаузального переходу може
                  супроводжуватися появою характерних неприємних симптомів.
                  Зниження рівня жіночих статевих гормонів – естрогенів не
                  проходить непоміченим,і деякі жінки все частіше починають
                  відзначати зміни настрою, припливи, знижується статевий потяг,
                  виникають сухість і свербіж піхви. Однак всі ці зміни, скоріше
                  за все, можуть стати приводом до дії, а не причиною для
                  смутку. Та й дії прості та зрозумілі: здоровий спосіб життя,
                  дієта, розумне фізичне навантаження, своєчасне відвідування
                  лікаря для профілактичних оглядів і, за необхідності, прийом
                  прописаних лікарем лікарських засобів, що утримують
                  гормональний дисбаланс.
                </Card.Title>
                <Card.Text
                  style={{ fontSize: 14, marginTop: 5, color: "grey" }}
                  className="text-right"
                >
                  28 Березня 2020
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Footer />
      </Container>
    );
  }
}

export default News;
