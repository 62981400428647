import React from "react";

import Header from "../Header";
import Footer from "../Footer";

import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";

import "../../css/Doctors.css";

import Image from "react-bootstrap/Image";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import ListGroup from "react-bootstrap/ListGroup";

class Karpyshun extends React.Component {
  render() {
    return (
      <Container fluid style={{ marginTop: "5%" }}>
        <Header />
        {/* <NavBar /> */}
        <div className="row">
          <p className="text-left h2 top-h ml-3">
            Карпишин Анатолій Олександрович
          </p>
        </div>
        <div className="row">
          <Col xs={12} md={12} lg={6} className="padding">
            <Image
              src={require("../../assets/karpyshun_info.png")}
              fluid
              className="w-100 top"
            />
          </Col>
          <Col xs={12} md={12} lg={6}>
            <p className="text-center fontSize25" style={{ paddingTop: 25 }}>
              Медичний директор центру сімейного здоров’я “ЛІАМЕД”, лікар
              загальної практики вищої категорії .
              <br /> Загальний стаж роботи 27 років.
            </p>
            <p className="text-left" style={{ fontSize: 20, fontWeight: 100 }}>
              <p
                className="text-justify bottom doctorsFont"
                style={{ fontSize: 15.8, fontWeight: 100 }}
              >
                Закінчив Вінницький медінститут ім. М.І.Пирогова.У 2018 р.
                отримав сертифікат лікаря-спеціаліста за спеціальністю
                Організація і управління охороною здоров'я. Постійний учасник
                науково-практичних коференцій та конгресів, зокрема:
              </p>
              <Accordion defaultActiveKey="1" className="border">
                <Card>
                  <Accordion.Toggle as={Card.Header} eventKey="0">
                    Заслуги
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey="0">
                    <Card.Body>
                      <ListGroup style={{ fontSize: 15 }}>
                        <ListGroup.Item>
                          • Cемінар-тренінг “Невідкладні стани в практиці
                          сімейного лікаря”, м.Київ, 2019 р.
                        </ListGroup.Item>
                        <ListGroup.Item>
                          • Науково-практична конференція “Сучасні методи
                          лікування гіпертонічної хвороби”, м.Житомир, 2019 р.
                        </ListGroup.Item>
                        <ListGroup.Item>
                          • Семінар "Організаційні питання діяльності та
                          найбільш часті клінічні проблеми в практиці сімейного
                          лікаря", м. Житомир, 2017р.
                        </ListGroup.Item>
                        <ListGroup.Item>
                          • Семінар-тренінг "Організаційні питання діяльності та
                          найбільш часті клінічні проблеми в практиці сімейного
                          лікаря", м. Житомир, 2016 р.
                        </ListGroup.Item>
                        <ListGroup.Item>
                          • Тренінг "Ефективне консультування з питань
                          імунізації", м. Київ, 2016 р.
                        </ListGroup.Item>
                        <ListGroup.Item>
                          • Науково-практичний телеміст "Лікування кашлю з
                          позиції доказової медицини. Прості відповіді на
                          складні питання", 2016р.
                        </ListGroup.Item>
                        <ListGroup.Item>
                          • Цикл семінарів у форматі телемосту
                          "Мультидисциплінарний підхід до лікування пацієнтів з
                          цереброваскулярною патологією та менеджмент болю",
                          2016р.
                        </ListGroup.Item>
                        <ListGroup.Item>
                          • Тренінг "Проблеми цукрового діабету", м. Житомир,
                          2016р.
                        </ListGroup.Item>
                        <ListGroup.Item>
                          • On-line навчання Національної медичної академії
                          післядипломної освіти імені П.Л. Шупика МОЗ України
                          "Недостатність вітаміну D в генезі порушень
                          репродуктивного здоровя", 2015 р.
                        </ListGroup.Item>
                        <ListGroup.Item>
                          • З серпня 2015р. працював завідуючим відділенням
                          загальної практики сімейної медицини КУЦЕМЛ №1 м.
                          Житомира.
                        </ListGroup.Item>
                      </ListGroup>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              </Accordion>
            </p>
            {/* <p className="text-center fontSize25">
              Медичний директор центру сімейного здоров’я “ЛІАМЕД”, лікар
              загальної практики вищої категорії .
              <br /> Загальний стаж роботи 27 років.
            </p> */}
            {/* 22.5 25  */}
            {/* <p className="text-center fontSize225">
              Закінчив Вінницький медінститут ім. М.І.Пирогова.У 2018 р. отримав
              сертифікат лікаря-спеціаліста за спеціальністю Організація і
              управління охороною здоров'я.
            </p> */}
          </Col>
        </div>
        <Footer />
      </Container>
    );
  }
}

export default Karpyshun;
