import React from "react";

import Header from "../Header";
import Footer from "../Footer";

import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";

import "../../css/Doctors.css";

// import Card from "react-bootstrap/Card";
// import CardDeck from "react-bootstrap/CardDeck";
// import CardGroup from "react-bootstrap/CardGroup";

import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import ListGroup from "react-bootstrap/ListGroup";

class Mastimenko extends React.Component {
  render() {
    return (
      <Container fluid style={{ marginTop: "5%", height: '98vh', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
        <Header />
        {/* <NavBar /> */}
        <div className="row">
          <p className="text-left h2 top-h ml-3">Мастіменко Альона Геннадіївна</p>
        </div>
        <div className="row" style={{ paddingBottom: "10%" }}>
          {/* <Col xs={12} md={12} lg={6} className="padding">
            <Image
              src={require("../../assets/karpyshun_info.png")}
              fluid
              className="w-100 top"
            />
          </Col> */}
          <Col xs={12} md={12} lg={12}>
            <p className="text-center fontSize25" style={{ paddingTop: 25 }}>
              Лікар акушер-гінеколог, репродуктолог, фахівець УЗД
              <br />
              Загальний стаж роботи лікарем 11 років.
            </p>
            <p
              className="text-left"
              style={{ fontSize: 20, fontWeight: 100 }}
            >
              <p
                className="text-justify bottom doctorsFont"
                style={{ fontSize: 15.8, fontWeight: 100 }}
              >
                Закінчила у 2012 році Національний медичний університет ім. А.А.Богомольця, член асоціації акушерів-гінекологів України.
              </p>
              <Accordion defaultActiveKey="1" className="border">
                <Card>
                  <Accordion.Toggle as={Card.Header} eventKey="0">
                    Заслуги
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey="0">
                    <Card.Body>
                      <ListGroup style={{ fontSize: 15 }}>
                        <ListGroup.Item>
                          • Регулярно бере участь у курсах підвищення кваліфікації, конференціях.
                        </ListGroup.Item>
                        <ListGroup.Item>
                          • "Спеціалізація з УЗД", НМАПО ім. П.Л. Шупика, Київ, 2017р.
                        </ListGroup.Item>

                      </ListGroup>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              </Accordion>
            </p>
          </Col>
        </div>
        <Footer />
      </Container>


    );
  }
}

export default Mastimenko;
