import React from "react";

import Header from "../Header";
import Footer from "../Footer";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Image from "react-bootstrap/Image";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};
class Contacts extends React.Component {
  render() {
    return (
      <Container fluid style={{ marginTop: "6%", backgroundColor: "white" }}>
        <Header />
        <Row
          style={{
            paddingLeft: 15,
            alignItems: "center",
            justifyContent: "center",
          }}
          className="fontPiedra"
        >
          <div style={{ fontWeight: "bold", fontSize: 40 }}>Контакти</div>
        </Row>
        {/* <NavBar /> */}
        <Carousel
          responsive={responsive}
          keyBoardControl={true}
          showDots={true}
          infinite={true}
          className="top bottom"
          autoPlay={true}
        >
          <Image
            src={require("../../assets/liamed-contact1.jpeg")}
            // rounded
            fluid
            className="w-100"
          />
          <Image
            src={require("../../assets/liamed-contact2.jpeg")}
            // rounded
            fluid
            className="w-100"
          />
          <Image
            src={require("../../assets/liamed-contact5.jpeg")}
            // rounded
            fluid
            className="w-100"
          />
          <Image
            src={require("../../assets/liamed-contact4.jpeg")}
            // rounded
            fluid
            className="w-100"
          />
        </Carousel>
        <Row
          style={{
            paddingLeft: 15,
            paddingRight: 20,
            flexDirection: "row",
            justifyContent: "space-around",
            height: "100%",
            alignItems: "center",
            flexWrap: "wrap",
            // fontFamily:'Piedra cursive'
          }}
          className="fontPiedra  border-5"
        >
          <div className="app-shadow" style={{ padding: 50, borderRadius: 50 }}>
            м. Житомир, вул. Перемоги, 53
            <br />
            Premier Hall, 2-й поверх
          </div>
          <div
            className="app-shadow"
            style={{
              // backgroundColor: "orange",
              padding: 40,
              paddingTop: 25,
              paddingBottom: 25,
              borderRadius: 500,
              marginTop: 20,
              marginBottom: 10,
            }}
          >
            Чекаємо на вас:
            <br />
            Пн-Пт: з 8:00 до 20:00
            <br />
            СБ: з 8:00 до 14:00
            <br />
            Неділя: Вихідний
          </div>
          <div
            className="app-shadow"
            style={{
              // backgroundColor: "grey",
              padding: 50,
              // paddingTop: 25,
              // paddingBottom: 25,
              borderRadius: 50,
              marginBottom: 10,
              marginTop: 20,
            }}
          >
            +38 067 007-19-19
            <br />
            +38 073 007-19-19
          </div>
        </Row>
        <div style={{ width: "100%", marginTop: 40 }}>
          <iframe
            title="map_contact"
            width="100%"
            height="500"
            src="https://maps.google.com/maps?width=100%&amp;height=600&amp;hl=ru&amp;coord=50.2653999,28.6461298&amp;q=%D0%9F%D0%B5%D1%80%D0%B5%D0%BC%D0%BE%D0%B3%D0%B8%2053+(My%20Business%20Name)&amp;ie=UTF8&amp;t=&amp;z=14&amp;iwloc=B&amp;output=embed"
            frameborder="0"
            scrolling="no"
            marginheight="0"
            marginwidth="0"
          ></iframe>
        </div>
        <Footer />
      </Container>
    );
  }
}

export default Contacts;
