import React from "react";
import Table from "react-bootstrap/Table";
import { _1, _2 } from "../text_data/text_doctors_actions.js";

export default class Actions extends React.Component {
  render() {
    return (
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>#</th>
            <th colSpan="3">Назва</th>
            <th>Вартість</th>
          </tr>
        </thead>
        <tbody>
          {_1.map((data, index) => {
            return (
              <tr>
                <th style={{ fontWeight: "normal" }}>{index + 1}</th>
                <th colSpan="3" style={{ fontWeight: "normal" }}>
                  {data.text}
                </th>
                <th style={{ fontWeight: "normal" }}>{data.price} грн</th>
              </tr>
            );
          })}
          <tr>
            <th
              colSpan="5"
              style={{
                fontWeight: "normal",
                paddingTop: 20,
                paddingBottom: 20,
              }}
            >
              Лазерні процедури (FR x Intenz CO2 лазер ):
            </th>
          </tr>
          {_2.map((data, index) => {
            return (
              <tr>
                <th style={{ fontWeight: "normal" }}>
                  {_1.length + index + 1}
                </th>
                <th style={{ fontWeight: "normal" }} colSpan="3">
                  {data.text}
                </th>
                <th style={{ fontWeight: "normal" }}>{data.price} грн</th>
              </tr>
            );
          })}
        </tbody>
      </Table>
    );
  }
}
