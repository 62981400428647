import React from "react";

import Modal from "react-bootstrap/Modal";

class SuccessMessage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      viber: false,
      whatsapp: false,
      youtube: false,
      show: true,
      inputs: {
        name: "",
        tel: "",
        date: "",
        doctor: "",
        captcha: "",
      },
    };
  }

  handleClose = () => {
    this.setState({ show: false });
  };

  handleShow = () => {
    this.setState({ show: true });
  };
  render() {
    return (
      <Modal
        show={this.state.show}
        onHide={() => this.handleClose()}
        centered
        className="text-left"
      >
        <Modal.Title>
          <div className="text-center top">Дякуємо, що записались</div>
        </Modal.Title>

        <Modal.Body>
          <div className="App text-center" style={{ fontWeight: "bold" }}>
            З вами зв'яжуться найближчим часом
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}
export default SuccessMessage;
