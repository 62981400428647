import React from "react";

import Header from "../Header";
import Footer from "../Footer";
import NavBar from "../NavBar";

import { Link } from "react-router-dom";
import "../../css/About.css";
import "../../css/Reproductive.css";


import Card from "react-bootstrap/Card";
import Container from "react-bootstrap/Container";
import Image from "react-bootstrap/Image";
import Col from "react-bootstrap/Col";

class Reproductive extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() { }
  render() {
    return (
      <Container fluid style={{ marginTop: "6%" }}>
        <Header />
        <NavBar />
        <div className="row health">
          <p id="aboutHeader" className="text-center">
            Довірте здоров’я вашої сім’ї – найсильнішим!
          </p>
        </div>
        <div className="row">
          <Col xs={12} md={12} lg={6} className="padding text-center">
            <Image src={require("../../assets/reproductive.jpg")} fluid />
          </Col>
          <Col xs={12} md={12} lg={6}>
            <p className="text">
              <span className="text-green">Репродуктолог </span> – це лікар, який займається лікуванням безпліддя у жінок та чоловіків. До нього звертаються, якщо довго не виходить завагітніти. Фахівець призначає обстеження та допомагає знайти причину хвороби. Після цього підбирає ефективну терапію. Сучасна медицина дозволяє подолати безпліддя більш як у 50% випадків.Консультація репродуктолога – це перший, але найважливіший крок на шляху до усвідомленого материнства. Пройти її можна як у клініці, так і онлайн.
            </p>


            <div className="text">  <span className="text-green">Консультація репродуктолога потрібна, якщо:</span>
              <ul>
                <li>вагітність не настає протягом 6 (у жінок старше 35 років) або 12 місяців;</li>
                <li>діагноз було поставлено жінці гінекологом, або чоловікові урологом;</li>
                <li>були викидні або завмерлі вагітності;</li>
                <li>у чоловіка погані показники спермограми;</li>
                <li>один із партнерів раніше перебував у безплідному шлюбі;</li>
                <li>народження дитини планується відкласти на більш зрілий вік;</li>
                <li>безплідність діагностовано у близьких родичів.</li>
              </ul>
            </div>
            <p className="text"> <span className="text-green">Репродуктолог</span> – це вузькоспеціалізований лікар, який займається питаннями зачаття. Тільки він може точно оцінити проблему та підібрати найкращий варіант вирішення.</p>

          </Col>
        </div>
        <div
          className="d-flex justify-content-around flex-wrap "
          style={{
            padding: 30,
            paddingLeft: 10,
            paddingRight: 10,
            marginLeft: 20,
          }}
        >

          <Card className="width">
            <Card.Img variant="top" src={require("../../assets/shyanova.jpg")} />
            <Card.Body>
              <Card.Title>Шиянова Світлана Володимирівна</Card.Title>
              <Card.Text>
                Експерт-репродуктолог
              </Card.Text>
            </Card.Body>
            <Card.Footer>
              <Link to="/shyanova" target="_blank">
                Детальніше
              </Link>
            </Card.Footer>
          </Card>

          <Card className="width">
            <Card.Img
              variant="top"
              src={require("../../assets/mastimenko.jpg")}
            />
            <Card.Body>
              <Card.Title>Мастіменко Альона Геннадіївна</Card.Title>
              <Card.Text>
                Лікар акушер-гінеколог, репродуктолог, фахівець УЗД
              </Card.Text>
            </Card.Body>
            <Card.Footer>
              <Link to="/mastimenko" target="_blank">
                Детальніше
              </Link>
            </Card.Footer>
          </Card>

        </div>
        <Footer />
      </Container>
    );
  }
}

export default Reproductive;
