import React from "react";

import Header from "../Header";
import Footer from "../Footer";

import "../../css/Reviews.css";

import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";

import "../../css/About.css";

class Reviews extends React.Component {
  render() {
    return (
      <Container fluid style={{ marginTop: "6.1%" }}>
        <Header />
        {/* <NavBar /> */}
        <Row style={{ padding: 30 }} className="font-family font-italic">
          <Col>
            <Row
              style={{ justifyContent: "space-between", flexDirection: "row" }}
            >
              <h4 className="border-bottom border-info ">Прищепа Елена</h4>
              <div style={{ marginRight: 30, fontSize: 20, color: "grey" }}>
                9 Березня 2020
              </div>
            </Row>
            <Row
              style={{
                paddingRight: 10,
              }}
            >
              <div>
                От души благодарю замечательного врача и Человека — Карпишину
                Лилию Владимировну за ее отношение к пациентам, высокий
                профессионализм, благородное сердце и отзывчивость.
              </div>
            </Row>
          </Col>
          <Col>
            <Row
              style={{ justifyContent: "space-between", flexDirection: "row" }}
            >
              <h4 className="border-bottom border-info">Сидоренко Наталия</h4>
              <div
                style={{
                  marginRight: 30,
                  fontSize: 20,
                  color: "grey",
                  paddingLeft: 5,
                }}
              >
                3 Березня 2020
              </div>
            </Row>
            <Row style={{ paddingRight: 10 }}>
              Хочу выразить большую благодарность врачу Товщик Н.В за
              профессионализм, внимательность и корректное обращение к пациенту.
              Побольше бы таких врачей как Наталия Викторовна!
            </Row>
          </Col>
        </Row>
        <Row
          style={{ padding: 30, paddingTop: 15 }}
          className="font-family font-italic"
        >
          <Col>
            <Row
              style={{ justifyContent: "space-between", flexDirection: "row" }}
            >
              <h4
                className="border-bottom border-info"
                style={{ paddingRight: 10 }}
              >
                Устименко Ольга
              </h4>
              <div style={{ marginRight: 30, fontSize: 20, color: "grey" }}>
                19 Лютого 2020
              </div>
            </Row>
            <Row style={{ paddingRight: 10 }}>
              Сегодня была в клинике «Ліамед» . Прекрасный квалифицированный
              персонал. Замечательный, внимательный, высоко профессиональный
              врач гинеколог Карпишина Лилия Владимировна, после знакомства с
              ней , поход к гинекологу перестал быть кошмаром. Всё объяснит, всё
              расскажет, разложит по полочкам! После долгих хождений, я наконец
              нашла…
            </Row>
          </Col>
          <Col>
            <Row
              style={{ justifyContent: "space-between", flexDirection: "row" }}
            >
              <h4 className="border-bottom border-info">Палько Анастасія</h4>
              <div style={{ marginRight: 30, fontSize: 20, color: "grey" }}>
                7 Лютого 2020
              </div>
            </Row>
            <Row style={{ paddingRight: 10 }}>
              Хочу залишити подяку Карпишиній Л.В. , яка допомогла нам із
              чоловіком стати батьками! Завдяки високому професійному рівню ,
              правильному підході до обстеження та лікування , ми тепер маємо
              маленьке щастя. Дякуємо Вам, Лілія Володимирівна!
            </Row>
          </Col>
        </Row>
        <Row style={{ padding: 30 }} className="font-family font-italic">
          <Col>
            <Row
              style={{ justifyContent: "space-between", flexDirection: "row" }}
            >
              <h4 className="border-bottom border-info">Шелегатська Ірина</h4>
              <div style={{ marginRight: 30, fontSize: 20, color: "grey" }}>
                27 Січня 2020
              </div>
            </Row>
            <Row style={{ paddingRight: 10 }}>
              Була на лазерному ліфтингу в Карпишиної Л.В. Довго не могла
              наважитись на дану процедуру, але лікар мені доступно пояснила
              покази та протипокази. Вже на другій процедурі я відчула
              позитивний ефект, а також дуже зручно, що вона проводиться раз у
              місяць…
            </Row>
          </Col>
          <Col>
            <Row
              style={{ justifyContent: "space-between", flexDirection: "row" }}
            >
              <h4 className="border-bottom border-info">Федорчук Лариса</h4>
              <div style={{ marginRight: 30, fontSize: 20, color: "grey" }}>
                20 Січня 2020
              </div>
            </Row>
            <Row style={{ paddingRight: 10 }}>
              Очень осталась довольна Вашей клиникой! Уютная обстановка,
              приятный персонал , квалифицированные врачи, что помогает
              расслабиться и забыть о том , что находишься в медицинском
              учреждении! Спасибо что Вы есть!!!
            </Row>
          </Col>
        </Row>
        <Row style={{ padding: 30 }} className="font-family font-italic">
          <Col>
            <Row
              style={{ justifyContent: "space-between", flexDirection: "row" }}
            >
              <h4 className="border-bottom border-info">Леся Можаровская</h4>
              <div style={{ marginRight: 30, fontSize: 20, color: "grey" }}>
                5 Квітня 2020
              </div>
            </Row>
            <Row style={{ paddingRight: 10 }}>
              Пользуюсь услугами клиники LIAMED. Удобно, быстро, не нужно ждать
              в очереди. Вежливый персонал. Отдельное спасибо доктору. Поповская
              А. Л. Очень приятный и приятный специалист, чувствительный
              человек. На приеме выслушали, все объяснили, рекомендовали и
              ответили на все мои вопросы. Теперь только к ней. Были бы такие
              профессионалы.
            </Row>
          </Col>
        </Row>
        {/* <div style={{ height: window.screen.availHeight / 10 }}></div> */}
        <Footer />
      </Container>
    );
  }
}

export default Reviews;
