export const _1 = [
  { text: "Консультація дієтолога-нутріціолога", price: 490 },
  { text: "Розширена консультація дієтолога-нутріціолога", price: 890 },
  { text: "Консультація інших лікарів", price: 600 },
  { text: "Акушерський комплексний огляд (первинний)", price: 1050 },
  {
    text: "Акушерський комплексний огляд (повторний)",
    price: 650,
  },
  {
    text: "Аспіраційна біопсія з порожнини матки з ПГД дослідженням",
    price: 1500,
  },
  {
    text: "Біопсія шийки матки",
    price: 1500,
  },
  {
    text: "Бужування цервікального каналу",
    price: 170,
  },
  {
    text: "Введення вагінального тампону",
    price: 170,
  },
  {
    text: "Видалення внутрішньоматкової спіралі",
    price: 650,
  },
  {
    text: "Встановлення внутрішньоматкової спіралі (Джайдес)",
    price: 4500,
  },
  {
    text: "Встановлення внутрішньоматкової спіралі (Мірена)",
    price: 5500,
  },
  {
    text: "Встановлення внутрішньоматкової спіралі",
    price: 800,
  },
  {
    text: "Інєкція в шийку матки",
    price: 170,
  },
  {
    text: "Контурна інтимна пластика (інтимний філлінг)",
    price: 7450,
  },
  {
    text:
      "Лазерне фракційне відбілювання інтимних зон ( великі і малі статеві губи, промежина, перианальна зона), 1 процедура",
    price: 3500,
  },
  {
    text:
      "Лазерне лікування нетримання сечі (вагінальний ліфтинг+зона уретри), 1 процедура",
    price: 2500,
  },
  {
    text:
      "Лазерне лікування опущення стінок піхви і матки ( лазерний вагінальний ліфтинг), 1 процедура",
    price: 2000,
  },
  {
    text:
      "Лазерне омолодження інтимних зон (великі і малі статеві губи, зона уретри ), 1 процедура",
    price: 2500,
  },
  {
    text:
      "Лазерне лікування вульво-вагінального старіння ( лазерний вагінальний ліфтинг + омолодження зовнішніх статевих органів), 1 процедура",
    price: 3000,
  },
  {
    text:
      "Місцеве знеболення ( крем з анестетиком, для лазерних омолоджуючих процедур)",
    price: 300,
  },
  {
    text: "Лазерне лікування дисплазії шийки матки (в межах 1-3 зони)",
    price: 4000,
  },
  {
    text:
      "Лазерне лікування дисплазії шийки матки (3 зони з переходом на склепіння)",
    price: 4800,
  },
  {
    text:
      "Лазерне лікування посттравматичної ектопії циліндричного епітелію (в межах 1-3 зони)",
    price: 3800,
  },
  {
    text:
      "Лазерне видалення екзофітних кондилом (ціна за одне видалення) під місцевим знеболенням",
    price: 480,
  },
  {
    text: "Лазерне лікування кісти піхви та вульви",
    price: 1390,
  },
  {
    text: "Лазерне лікування післяпологових рубців піхви",
    price: 1280,
  },
  {
    text: "Лазерне лікування ретенційної кісти",
    price: 1390,
  },
  {
    text: "Лазерне лікування рубцевої деформації шийки матки",
    price: 3165,
  },
  {
    text: "Лазерне лікування субепітеліального ендометріозу шийки матки",
    price: 1390,
  },
  {
    text: "Лазерне лікування 2 зони",
    price: 3500,
  },
  {
    text: "Лазерне лікування 1 зони",
    price: 2500,
  },
  {
    text: "Лазерне лікування 1-3 зони",
    price: 5000,
  },
  {
    text: "Лікувальна інтравагінальна санація",
    price: 110,
  },
  {
    text: "Лікування патологій шийки матки за допомогою радіохвильової терапії",
    price: 1150,
  },
  {
    text: "Медикаментозне лікування конділоматозу, папіломатозу",
    price: 480,
  },
  {
    text: "Ниткова пластика піхви",
    price: 11950,
  },
  {
    text: "Плазмоліфтинг інтимної зони (1 пробірка)",
    price: 2200,
  },
  {
    text: "Плазмоліфтинг інтимної зони (2 пробірки)",
    price: 3300,
  },
  {
    text: "Поліпектомія цервікального каналу шийки матки. Вишкрібання ц/к",
    price: 1200,
  },
  {
    text: "РАР-тест (скринінгове дослідження мазка шийки матки)",
    price: 530,
  },
  {
    text: "Цервікальний скринінг (рідинна цитологія ПАП-тест Cell-Prep )",
    price: 610,
  },
  {
    text: "Цервікальний скринінг (рідинна цитологія + ВПЛ тестування )",
    price: 780,
  },
  {
    text:
      "Цервікальний скринінг ( 3 показники: ПАП-котест + маркери проліферації )",
    price: 1660,
  },
  {
    text: 'Вакцинація від ВПЛ (Вірусу папіломи людини) "Церварікс"',
    price: 2000,
  },
];
export const _2 = [
  {
    text: "Корекція зморшок та постакне, 1 сеанс",
    price: 2000,
  },
  {
    text: "Корекція глибоких зморшок, 1 сеанс",
    price: 2200,
  },
  {
    text: "Реювеніалізація зони декольте, 1 сеанс",
    price: 2500,
  },
  {
    text: "Корекція стрій та дряблої шкіри ( зона 10х10 см )",
    price: 2000,
  },
  {
    text: "Корекція періоральної ділянки (“кисетні зморшки”), 1 сеанс",
    price: 1500,
  },
  {
    text: "Корекція пігментації кистей рук, 1 сеанс",
    price: 2000,
  },
  {
    text: "Корекція рубців та шрамів (зона 3-5см2), 1 сеанс",
    price: 1200
  }
];
