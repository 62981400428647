import React from "react";

import Navbar from "react-bootstrap/Navbar";

import Nav from "react-bootstrap/Nav";
import { Link } from "react-router-dom";
import "./../css/navBar.css";

class NavBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      color: "",
    };
  }
  render() {
    return (
      <Navbar
        collapseOnSelect
        expand="lg"
        variant="light"
        style={{
          // backgroundColor: "#e9e4e8",
          borderBottomLeftRadius: 5,
          borderBottomRightRadius: 5,
          position: "fixed",
          padding: 5,
          zIndex: 9000,
          width: "100%",
          marginLeft: -15,
          // margin: 0,
          top: 0,
        }}
        className="grad-grad"
      >
        <div className="text-left d-flex d-lg-none navBarContact">
          <Link to="/">
            <img
              alt="logo"
              src={require("../assets/logo-header.png")}
              style={{ width: 120, height: 60, padding: 5, paddingRight: 0 }}
            />
          </Link>
          <div>
            <p
              style={{
                fontWeight: "bold",
                width: "100%",
                height: "50%",
              }}
            >
              +38 067 007-19-19
              <br />
              +38 073 007-19-19
              <br />
              ТЦ Premier Hall, 2-й поверх
            </p>
          </div>

        </div>



        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Navbar className="d-none d-lg-block">
            <Navbar.Brand >
              <div className='navBarContact'>
                <Link to="/">
                  <img
                    src={require("../assets/logo-header.png")}
                    width="140"
                    height="60"
                    className="d-inline-block align-top"
                    alt="Ліамед"
                  />
                </Link>

                {/* <p
                  style={{
                    fontWeight: "bold",

                    width: "100%",
                  }}
                >
                  м. Житомир, вул. Перемоги, 53
                  <br />
                 ТЦ Premier Hall, 2-й поверх
                </p> */}
                <p
                  style={{
                    fontWeight: "bold",
                    width: "100%",
                    height: "50%",
                  }}
                >
                  +38 067 007-19-19
                  <br />
                  +38 073 007-19-19
                  <br />
                  ТЦ Premier Hall, 2-й поверх
                </p>
              </div>
            </Navbar.Brand>
          </Navbar>
          <Nav
            className="mr-auto"
            style={{
              flex: 1,
              justifyContent: "flex-end",
              fontSize: 20,
              fontWeight: "bold",
            }}
          >
            {/* <div className="text-left d-flex d-lg-none navBarContact">
              <p

                style={{
                  fontWeight: "bold",

                  width: "100%",
                }}
              >
                м. Житомир, вул. Перемоги, 53
                <br />
                Premier Hall, 2-й поверх
              </p>
              <p
                style={{
                  fontWeight: "bold",
                  width: "100%",
                  height: "50%",
                }}
              >
                +38 067 007-19-19
                <br />
                +38 073 007-19-19
              </p>
            </div> */}

            <Nav.Link href="/" className="margin-right onlyBar">
              <span>Головна</span>
            </Nav.Link>
            <Nav.Link href="/reproductive" className="margin-right onlyBar">
              <span>Репродуктологія</span>
            </Nav.Link>
            <Nav.Link href="/about" className="margin-right onlyBar">
              <span>Про клініку</span>
            </Nav.Link>
            <Nav.Link href="/doctors" className="margin-right onlyBar">
              <span>Лікарі</span>
            </Nav.Link>
            <Nav.Link href="/prices" className="margin-right onlyBar">
              <span>Послуги та ціни</span>
            </Nav.Link>
            <Nav.Link href="/contacts" className="margin-right onlyBar">
              <span>Контакти</span>
            </Nav.Link>
            <Nav.Link href="/reviews" className="margin-right onlyBar">
              <span>Відгуки</span>
            </Nav.Link>
            <Nav.Link href="/news" className="margin-right onlyBar">
              <span>Новини</span>
            </Nav.Link>
            {/* <Nav.Link href="/test">
              <span>Test</span>
            </Nav.Link> */}
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    );
  }
}

export default NavBar;
