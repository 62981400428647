import React from "react";

import Header from "../Header";
import Footer from "../Footer";

import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";

import "../../css/Doctors.css";

import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import ListGroup from "react-bootstrap/ListGroup";

class Miheeva extends React.Component {
  render() {
    return (
      <Container fluid style={{ marginTop: "5%" }}>
        <Header />
        {/* <NavBar /> */}
        <div className="row">
          <p className="text-left h2 top-h ml-3">Міхєєва Світлана Юріївна</p>
        </div>
        <div className="row" style={{ paddingBottom: "15%" }}>
          {/* <Col xs={12} md={12} lg={6} className="padding">
            <Image
              src={require("../../assets/karpyshun_info.png")}
              fluid
              className="w-100 top"
            />
          </Col> */}
          <Col xs={12} md={12} lg={6}>
            <p className="text-center fontSize25" style={{ paddingTop: 25 }}>
              Лікар — невропатолог вищої категорії.
              <br />
              Загальний стаж роботи 37 років.
            </p>
            <p className="text-left" style={{ fontSize: 20, fontWeight: 100 }}>
              <p
                className="text-justify bottom doctorsFont"
                style={{ fontSize: 15.8, fontWeight: 100 }}
              >
                Закінчила Томський медичний інститут по спеціальності”
                Лікувальне справа”. Пройшла спеціалізацію по спеціальності “
                Неврологія” на базі Київської медичної академії післядипломної
                освіти ім.Шупіка МОЗ України. Має досвід роботи лікаря —
                невропатолога поліклініки №1 КП “Лікарня №1” <br />
                Профілактика інсультів, вертеброгенні ураження хребта,
                астенодепресивні розлади , мігрені , головні болі різної
                етіології . Лікувальні блокади.
              </p>
              <Accordion defaultActiveKey="1" className="border">
                <Card>
                  <Accordion.Toggle as={Card.Header} eventKey="0">
                    Заслуги
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey="0">
                    <Card.Body>
                      <ListGroup style={{ fontSize: 15 }}>
                        <ListGroup.Item>
                          Постійно приймає участь в професійних науково-
                          практичних конференціях та семінарах.
                        </ListGroup.Item>
                      </ListGroup>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              </Accordion>
            </p>
            {/* <p className="text-center fontSize25">
              Лікар — невропатолог вищої категорії.
              <br />
              Загальний стаж роботи 37 років.
            </p> */}
            {/* 22.5 25  */}
            {/* <p className="text-center fontSize225">
              Закінчив Вінницький медінститут ім. М.І.Пирогова.У 2018 р. отримав
              сертифікат лікаря-спеціаліста за спеціальністю Організація і
              управління охороною здоров'я.
            </p> */}
          </Col>
        </div>
        <Footer />
      </Container>
    );
  }
}

export default Miheeva;
