import React from "react";

import "../css/Header.css";
import Modal from "react-bootstrap/Modal";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import Jello from "react-reveal/Jello";
import { FaPhone } from "react-icons/fa";
import $ from "jquery";
import ReCAPTCHA from "react-google-recaptcha";
import ScrollUpButton from "react-scroll-up-button"; //Add this line Here
import SuccessMessage from "./header/components/success_message";

import { Field, formInputData, formValidation } from "reactjs-input-validator";

import NavBar from "./NavBar";
class Header extends React.Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.state = {
      viber: false,
      whatsapp: false,
      youtube: false,
      message: false,
      show: false,
      inputs: {
        name: "",
        tel: "",
        date: "",
        doctor: "",
        captcha: "",
      },
      data: {},
    };
  }

  handleChange(event, inputValue, inputName, validationState, isRequired) {
    const value = (event && event.target.value) || inputValue;
    const { data } = this.state;
    data[inputName] = { value, validation: validationState, isRequired };
    this.setState({
      data,
    });
    // if you want access to your form data
    const formData = formInputData(this.state.data); // eslint-disable-line no-unused-vars
    // tells you if the entire form validation is true or false
    const isFormValid = formValidation(this.state.data); // eslint-disable-line no-unused-vars
  }

  handleSubmit(event) {
    event.preventDefault();
    const isFormValid = formValidation(this.state.data);

    if (isFormValid) {
      // do anything including ajax calls
      this.setState({ callAPI: true });
    } else {
      this.setState({ callAPI: true, shouldValidateInputs: !isFormValid });
    }
  }
  handleClose = () => {
    this.setState({ show: false });
  };

  handleShow = () => {
    this.setState({ show: true });
  };

  toggleHover = (from) => {
    switch (from) {
      case "viber":
        return this.setState({ viber: !this.state.viber });
      case "whatsapp":
        return this.setState({ whatsapp: !this.state.whatsapp });
      case "youtube":
        return this.setState({ youtube: !this.state.youtube });
    }
  };

  render() {
    return (
      // <Container fluid>
      <div>
        <NavBar />
        <div className="row">
          <Col
            xs={{ span: 12, order: 1 }}
            md={{ span: 4, order: 1 }}
            lg={{ span: 4, order: 1 }}
            className="my-auto d-flex justify-content-center align-items-center padding"
          >
            {/* <div className="col-md-4 col-xs-12 col-lg-4  my-auto d-flex justify-content-center align-items-center"> */}
            {/* <Link className="text-left d-none d-md-block" to="/">
              <img
                src={require("../assets/logo-header.png")}
                style={{ width: 300, height: 125 }}
              />
            </Link> */}
          </Col>
          {/* </div> */}

          {/* <div className="col-md-4 text-center col-xs-12 col-lg-4  my-auto "> */}
          {/* <Col
            xs={{ span: 12, order: 3 }}
            md={{ span: 4, order: 2 }}
            lg={{ span: 4, order: 2 }}
            className="my-auto  d-flex justify-content-center align-items-center padding"
          >
            <p class="text-center width">
              Чекаємо на вас:
              <br />
              Пн-Пт: з 8:00 до 20:00
              <br />
              СБ: з 8:00 до 14:00
              <br />
              Неділя: Вихідний
            </p>
          </Col> */}
          {/* </div> */}
          {/* <div className="col-md-4  col-xs-12 col-lg-4  my-auto justify-content-around d-flex align-items-center"> */}
          <Col
            xs={{ span: 12, order: 2 }}
            md={{ span: 4, order: 3 }}
            lg={{ span: 4, order: 3 }}
            className="my-auto  d-flex justify-content-between align-items-center padding "
          >
            <Jello delay={2000} count={10}>
              <div
                style={{
                  height: 50,
                  width: 50,
                  bottom: 90,
                  position: "fixed",
                  borderRadius: 30,

                  zIndex: 9999,
                  right: 20,
                }}
                className="green  app-shadow" //d-block d-sm-none
                onClick={() => this.handleShow()}
              >
                <FaPhone
                  style={{
                    width: 30,
                    height: 30,
                    marginLeft: "20%",
                    marginTop: "20%",
                  }}
                />
                {/* <FontAwesomeIcon icon="edit"  /> */}
              </div>
            </Jello>
            {/* <button
              // type="button"
              class="btn btn-lg btn-block green mt-5 d-none d-sm-block"
              onClick={() => this.handleShow()}
            >
              Записатися на прийом
            </button> */}
            {this.state.message ? <SuccessMessage /> : null}
            <Modal
              show={this.state.show}
              onHide={() => this.handleClose()}
              centered
              style={{ zIndex: 9999 }}
              className="text-left"
            >
              <Modal.Title>
                <div className="text-center top">Записатися на прийом</div>
              </Modal.Title>

              <Modal.Body>
                <div className="App">
                  <div>
                    <form
                      onSubmit={(event) => {
                        event.preventDefault();
                      }}
                      id="idForm"
                      // class="gForm"
                      // method="POST"
                      action="https://script.google.com/macros/s/AKfycbxiD1WN53aqT87z8wSzQlpJOS5JePQ-rjf488vFmw/exec"
                    >
                      <Row>
                        <Col md={12}>
                          {/*
              Input required validation check with
              library default error messages
            */}
                          <Field
                            required
                            label="Ім'я"
                            name="Имя"
                            placeholder="Введіть будь ласка ваше ім'я"
                            onChange={this.handleChange}
                            requiredErrMsg={
                              <div style={{ color: "red" }}>
                                Заповніть будь ласка поле з іменем
                              </div>
                            }
                            value={this.state.data.Имя}
                            shouldValidateInputs={
                              this.state.shouldValidateInputs
                            }
                          />
                        </Col>
                        <Col md={12}>
                          {/*
              Input required validation check with
              isEmail validation and
              library default error messages
            */}
                          <Field
                            validator="isEmail"
                            required
                            label="Email"
                            name="Email"
                            placeholder="example_test@gmail.com"
                            requiredErrMsg={
                              <div style={{ color: "red" }}>
                                Введіть будь ласка валідний емейл
                              </div>
                            }
                            onChange={this.handleChange}
                            value={this.state.data.Email}
                            shouldValidateInputs={
                              this.state.shouldValidateInputs
                            }
                          />
                        </Col>
                      </Row>

                      {/*
          Input required validation check with
          custom error msg only when required validation fail
        */}
                      <Field
                        required
                        requiredErrMsg={
                          <div style={{ color: "red" }}>
                            Введіть будь ласка свій номер телефону
                          </div>
                        }
                        label="Телефон"
                        name="Телефон"
                        type={"tel"}
                        onChange={this.handleChange}
                        value={this.state.data.Телефон}
                        shouldValidateInputs={this.state.shouldValidateInputs}
                      />

                      {/*
          No validation
        */}
                      <Field
                        required
                        label="Повідомлення"
                        name="Сообщение"
                        placeholder="Опишіть вашу проблему"
                        requiredErrMsg={
                          <div style={{ color: "red" }}>
                            У вас немає проблем?
                          </div>
                        }
                        onChange={this.handleChange}
                        value={this.state.data.Сообщение}
                        shouldValidateInputs={this.state.shouldValidateInputs}
                      />

                      <Row>
                        <Col md={6}>
                          {/*
              Input required validation check with
              maximum character length validation with
              library default error messages
            */}
                        </Col>
                      </Row>
                      <input
                        type="submit"
                        style={{
                          padding: 13,
                          border: "transparent",
                          borderRadius: 5,
                          backgroundColor: "lightgreen",
                        }}
                        onClick={(e) => {
                          if (
                            this.state.data.Телефон.value &&
                            this.state.data.Сообщение.value &&
                            this.state.data.Email.value &&
                            this.state.data.Имя.value &&
                            this.state.captcha
                          ) {
                            $("#idForm").submit(function (e) {
                              e.preventDefault(); // avoid to execute the actual submit of the form.

                              var form = $(this);
                              console.log("ergergregergergerg", form);
                              var url = form.attr("action");

                              $.ajax({
                                type: "POST",
                                url: url,
                                data: form.serialize(), // serializes the form's elements.
                                success: function (data) {
                                  // alert(data); // show response from the php script.
                                },
                              });
                              return $("#idForm").unbind("submit");
                            });
                          }

                          if (
                            this.state.data.Телефон.value &&
                            this.state.data.Сообщение.value &&
                            this.state.data.Email.value &&
                            this.state.data.Имя.value &&
                            this.state.captcha
                          ) {
                            console.log("this.state", this.state);
                            this.setState({ message: true });
                            this.handleClose();
                          }
                        }}
                        value="Записатись"
                      />
                      <button
                        type="button"
                        style={{
                          marginLeft: 5,
                          height: 50,
                          width: 125,
                          borderRadius: 7,
                          borderColor: "transparent",
                        }}
                        onClick={() => this.handleClose()}
                        value="Закрити"
                      >
                        Закрити
                      </button>
                      {/* <div>
                        {this.state.mailSent && (
                          <div>Thank you for contcting us.</div>
                        )}
                      </div> */}
                    </form>
                    <ReCAPTCHA
                      style={{ marginTop: 20 }}
                      sitekey="6LcxL_oUAAAAAJac8BeAwQaKRzr-qLG5X4cQveM_"
                      onChange={(value) => this.setState({ captcha: value })}
                    />
                  </div>
                </div>
              </Modal.Body>
            </Modal>
          </Col>
        </div>
        <ScrollUpButton
          ContainerClassName="AnyClassForContainer"
          TransitionClassName="AnyClassForTransition"
          style={{
            backgroundColor: "rgba(77, 175, 124, 0.6)",
            borderRadius: 30,
            opacity: 0.5,
          }}
        ></ScrollUpButton>
      </div>
      // </Container>
    );
  }
}
export default Header;
