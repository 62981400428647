import React from "react";

import Header from "../Header";
import Footer from "../Footer";

import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";

import "../../css/Doctors.css";

import Image from "react-bootstrap/Image";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import ListGroup from "react-bootstrap/ListGroup";

class Karpyshuna extends React.Component {
  render() {
    return (
      <Container fluid style={{ marginTop: "5%" }}>
        <Header />
        {/* <NavBar /> */}
        <div className="row">
          <p className="text-left h2 top-h ml-3">
            Карпишина Лілія Володимирівна
          </p>
        </div>
        <div className="row">
          <Col xs={12} md={12} lg={6} className="padding">
            <Image
              src={require("../../assets/karpyshyna_info.png")}
              fluid
              className="w-100 top"
            />
          </Col>
          <Col xs={12} md={12} lg={6}>
            <p className="text-center fontSize25" style={{ paddingTop: 25 }}>
              Провідний спеціаліст центру сімейного здоров’я "ЛІАМЕД",
              лікар-акушер-гінеколог вищої категорії.
              <br /> Загальний стаж роботи лікарем 27 років.
            </p>
            {/* 22.5 25  */}
            <p className="text-center fontSize225">
              Основна спеціалізація Ендокринна гінекологія: порушення циклу,
              безпліддя, патологічний клімакс. Діагностика та лікування
              запальних гінекологічних захворювань, патологій шийки матки.
              Кольпоскопія, УЗД в акушерстві та гінекології, лазерна
              вапоризація,кріодеструкція шийки матки. Підбір методів
              контрацепції, введення і видалення ВМС. Консультування пар з
              приводу планування вагітності. Естетична гінекологія:
              плазмоліфтінг інтимної зони, ниткова пластика піхви, контурна
              пластика, лазерний вагінальний ліфтинг.
            </p>
            <p className="text-left " style={{ fontSize: 20, fontWeight: 100 }}>
              <p
                className="text-justify bottom doctorsFont"
                style={{ fontSize: 15.8, fontWeight: 100 }}
              >
                Член української асоціації кольпоскопії і цервікальної
                патології. Член асоціації гінекологів-ендокринологів України.
                Закінчила Вінницький медичний інститут ім. М. І. Пирогова.
                Пройшла спеціалізацію за фахом «Акушерство і гінекологія» в
                Київському інституті удосконалення лікарів МОЗ України. Постійна
                учасниця науково-практичних конференцій і конгресів гінекологів,
                зокрема:
              </p>
              <Accordion defaultActiveKey="1" className="border">
                <Card>
                  <Accordion.Toggle as={Card.Header} eventKey="0">
                    Заслуги
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey="0">
                    <Card.Body>
                      <ListGroup style={{ fontSize: 15 }}>
                        <ListGroup.Item>
                          • Науково-практичний семінар «Репродуктивне здоров'я
                          жінки 40+», м.Київ, 2018р.
                        </ListGroup.Item>
                        <ListGroup.Item>
                          • Науково-практична конференція «Гільдія професіоналів
                          на варті жіночого здоров'я»,м.Київ,2018 р
                        </ListGroup.Item>
                        <ListGroup.Item>
                          • Конгрес з міжнародною участю
                          "Амбулаторно-поліклінічна практика: діагностика,
                          лікування, профілактика", м. Москва, 2016 р
                        </ListGroup.Item>
                        <ListGroup.Item>
                          • Майстер-клас "Кольпоскопія: теорія і практика", м.
                          Москва, 2016 р
                        </ListGroup.Item>
                        <ListGroup.Item>
                          • Школа клімактерії, семінар "Гармонія гормонів -
                          основа здоров'я жінки. Сучасний підхід до менеджменту
                          аномальної маткової кровотечі", м.Київ, 2015 р
                        </ListGroup.Item>
                        <ListGroup.Item>
                          • Семінар "Безпліддя, діагностика, методи лікування,
                          нові тенденції", м Житомир, 2015 р
                        </ListGroup.Item>
                        <ListGroup.Item>
                          • Науково-практична конференція "Менеджмент аномальних
                          маткових кровотеч», м. Київ, 2015 р
                        </ListGroup.Item>
                        <ListGroup.Item>
                          • Теоретичне навчання на семінарі "Актуальні питання в
                          ендокринної гінекології", м.Київ, 2015 р
                        </ListGroup.Item>
                        <ListGroup.Item>
                          • Сертифікація за методикою роботи на СО2 лазері
                          MedArt Energist Group: лазерне лікування патологій
                          шийки матки, г. Киев, 2014 р .
                        </ListGroup.Item>
                        <ListGroup.Item>
                          • Науково-практична конференція "Ендометріоз - хвороба
                          цивілізації", м.Київ, 2014 р
                        </ListGroup.Item>
                        <ListGroup.Item>
                          • Науково-практична конференція "Невиношування:
                          актуальність проблеми, терапевтичні підходи,
                          ефективність і безпеку гестрагенов", м.Київ, 2014 р
                        </ListGroup.Item>
                        <ListGroup.Item>
                          • Школа клімактерії, семінар "Гармонія гормонів -
                          основа здоров'я жінки", м.Київ, 2014 р
                        </ListGroup.Item>
                        <ListGroup.Item>
                          • Семінар "Актуальні питання в ендокринної
                          гінекології", м.Київ, 2014 р
                        </ListGroup.Item>
                        <ListGroup.Item>
                          • Конференція "Невиношування: актуальність проблеми,
                          терапевтичні підходи, ефективність і безпеку
                          гестагенів", м Житомир, 2013 р
                        </ListGroup.Item>
                        <ListGroup.Item>
                          • Майстер-клас з гістероскопії, м. Київ, 2013 р
                        </ListGroup.Item>
                        <ListGroup.Item>
                          • Міжнародна конференція "Дні замісної гормональної
                          терапії", м.Київ, 2013 р
                        </ListGroup.Item>
                        <ListGroup.Item>
                          • Форум "репродуктивної медицини в XXI столітті",
                          м.Київ, 2013 р
                        </ListGroup.Item>
                        <ListGroup.Item>
                          • Науково-практичний семінар "Подолання безпліддя. Від
                          простого до складного", м Житомир, 2013 р
                        </ListGroup.Item>
                        <ListGroup.Item>
                          • Семінар "Маркери хромосомної патології", м Житомир,
                          2013 р
                        </ListGroup.Item>
                        <ListGroup.Item>
                          • Семінар "Актуальні питання ендокринної гінекології",
                          м.Київ, 2013 р
                        </ListGroup.Item>
                        <ListGroup.Item>
                          • Світовий конгрес міжнародного суспільства
                          Гінекологічної ендокринології, м Флоренція, Італія,
                          2012 р{" "}
                        </ListGroup.Item>
                        <ListGroup.Item>
                          • Міжнародна науково-практична конференція
                          «Прогнозування репродуктивного здоров'я жінки -
                          сьогодні і перспективи розвитку», м Київ, 2012 р
                        </ListGroup.Item>
                      </ListGroup>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              </Accordion>
            </p>
          </Col>
        </div>
        <Footer />
      </Container>
    );
  }
}

export default Karpyshuna;
