import React from "react";
import Table from "react-bootstrap/Table";

export default class Consultation extends React.Component {
  render() {
    return (
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>#</th>
            <th colSpan="3">Назва</th>
            <th>Вартість</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>1</td>
            <td colSpan="3">Гінекологічний комплексний огляд</td>
            <td>1500 грн</td>
          </tr>
          <tr>
            <td>2</td>
            <td colSpan="3">Консультація лікаря в динаміці</td>
            <td>600 грн</td>
          </tr>
          <tr>
            <td>3</td>
            <td colSpan="3">Консультація лікаря в динаміці (повторна консультація)</td>
            <td>350 грн</td>
          </tr>
          <tr>
            <td>4</td>
            <td colSpan="3">Консультація гастроентеролога (первинна консультація)</td>
            <td>600 грн</td>
          </tr>
          <tr>
            <td>5</td>
            <td colSpan="3">Корекція лікування</td>
            <td>400 грн</td>
          </tr>
          <tr>
            <td>6</td>
            <td colSpan="3">Консультація акушера-гінеколога первинна</td>
            <td>600 грн</td>
          </tr>
          <tr>
            <td>7</td>
            <td colSpan="3">Консультація генетика</td>
            <td>650 грн</td>
          </tr>
          <tr>
            <td>8</td>
            <td colSpan="3">Консультація гінеколога з питань ендокринної патології</td>
            <td>650 грн</td>
          </tr>
          <tr>
            <td>9</td>
            <td colSpan="3"> Консультація з планування вагітності</td>
            <td>550 грн</td>
          </tr>
          <tr>
            <td>10</td>
            <td colSpan="3">Консультація сімейного лікаря (первинна консультація)</td>
            <td>450 грн</td>
          </tr>
          <tr>
            <td>11</td>
            <td colSpan="3">Консультація кардіолога (первинна консультація)</td>
            <td>600 грн</td>
          </tr>
          <tr>
            <td>12</td>
            <td colSpan="3"> Консультація невролога (первинна консультація)</td>
            <td>600 грн</td>
          </tr>
          <tr>
            <td>13</td>
            <td colSpan="3">Консультація ендокринолога (первинна консультація)</td>
            <td>600 грн</td>
          </tr>
          <tr>
            <td>13</td>
            <td colSpan="3"> Консультація пластичного хірурга </td>
            <td>375 грн</td>
          </tr>
        </tbody>
      </Table>
    );
  }
}
