import React from "react";
import Image from "react-bootstrap/Image";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import "./../../../../css/Content.css";
export default class Content extends React.Component {
  render() {
    return (
      <Row className="mx-auto mt-2">

        <Image
          src={require("../../../../assets/smiling_girl.jpg")}
          fluid
          className="d-block w-100 h-100 img padding-15"
        />
        <Col xs={12} md={12} lg={6}>
          <Image
            src={require("../../../../assets/home.jpg")}
            fluid
            className="fliping-img"
          />
        </Col>
        <Col className="text-right" xs={12} md={12} lg={6}>
          <div className="marginTB">ЕСТЕТИЧНА ЛАЗЕРНА ГІНЕКОЛОГІЯ</div>
          <div className="font">
            В нашому медичному центрі створені комфортні умови для лікування
            делікатних жіночих проблем. Це стало реальністю завдяки широким
            можливостям СО2 лазера. Перед початком процедури пацієнтка проходить
            огляд гінеколога. Процедура проводиться амбулаторно кваліфікованими
            лікарями. Жінка може відразу повернутися до звичного ритму життя.
            Для досягнення ефекту омолодження потрібно 4-5 процедур. Лазерний
            вагінальний ліфтинг протипоказаний при гострих запальних
            захворюваннях, доброякісних та злоякісних процесах, вагітності.
          </div>
          <div className="marginTB">
            ІННОВАЦІЙНЕ ЛАЗЕРНЕ ОМОЛОДЖЕННЯ – НАЙКРАЩА АЛЬТЕРНАТИВА ХІРУРГІЧНОМУ
            ЛІКУВАННЮ ТА ГОРМОНАЛЬНІЙ ТЕРАПІЇ.
          </div>
          <div className="font">
            Більшість урогенітальних захворювань виникають внаслідок зниження
            рівня жіночого гормону – естрогену. Це призводить до витончення
            епітелію піхви і появи сухості слизової, підвищеної чутливості та
            мікротравм. Гормональна замісна терапія вважалась золотим стандартом
            в лікуванні симптомів вульвовагінальної атрофії. Сечостатевий
            синдром часто виникає після пологів і в процесі вікових інволютивних
            змін. Втрата тонусу м’язів тазового дна лікувалась лише хірургічним
            методом.
          </div>
          <div className="marginTB">
            ТЕПЕР З’ЯВИЛАСЬ АЛЬТЕРНАТИВА . ЛАЗЕРНИЙ ВАГІНАЛЬНИЙ ЛІФТИНГ – ЛЕГКЕ,
            БЕЗГОРМОНАЛЬНЕ ЛІКУВАННЯ З МІНІМАЛЬНИМ ВТРУЧАННЯМ, ЯКЕ ПІДХОДИТЬ ДЛЯ
            ЖІНОК РІЗНОГО ВІКУ !
          </div>
          <div className="font">
            Головна мета ЛАЗЕРНОГО ЛІФТИНГУ – відновлення початкового
            метаболізму сполучної тканини піхви і покращення стану слизової за
            рахунок стимуляції процесу неоколагенезу. Під впливом лазерного
            випромінювання слизова оболонка відновлює свою еластичність та
            вологість. Тонус м’язів тазового дна покращується за рахунок
            зміцнення тканин піхви. Це усуває, або значно зменшує симптоми
            неутримання сечі, свербіжу, болі під час статевого життя, відновлює
            еластичність та тонус піхви. Більшість жінок відмічають значне
            покращення вже протягом першого місяця.
          </div>
          <div className="marginTB">
            ЛАЗЕРНИЙ ВАГІНАЛЬНИЙ ЛІФТИНГ – ВИСОКОЕФЕКТИВНИЙ, БЕЗОПЕРАЦІНИЙ МЕТОД
            ОМОЛОДЖЕННЯ ІНТИМНОЇ ЗОНИ.
          </div>
          <div className="font">
            Завдяючи цьому методу, можна швидко, безпесно, з мінімальним
            дискомфортом усунути небажані симптоми і набути знову радість
            комфорту життя і сексуальних відносин
          </div>
        </Col>
        <div style={{ width: "100%", marginTop: 63 }}>
          <iframe
            title="map"
            width="100%"
            height="600"
            src="https://maps.google.com/maps?width=100%&amp;height=600&amp;hl=ru&amp;coord=50.2653999,28.6461298&amp;q=%D0%9F%D0%B5%D1%80%D0%B5%D0%BC%D0%BE%D0%B3%D0%B8%2053+(My%20Business%20Name)&amp;ie=UTF8&amp;t=&amp;z=14&amp;iwloc=B&amp;output=embed"
            frameborder="0"
            scrolling="no"
            marginheight="0"
            marginwidth="0"
          ></iframe>
        </div>
        <br />
      </Row>
    );
  }
}
