import React from "react";

import Header from "../Header";
import Footer from "../Footer";

import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";

import "../../css/Doctors.css";

import Image from "react-bootstrap/Image";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import ListGroup from "react-bootstrap/ListGroup";

class pokrasova extends React.Component {
  render() {
    return (
      <Container fluid style={{ marginTop: "5%" }}>
        <Header />
        {/* <NavBar /> */}
        <div className="row">
          <p className="text-left h2 top-h ml-3">ПОКРАСОВА СВІТЛАНА АНАТОЛІЇВНА</p>
        </div>
        <div className="row">
          <Col xs={12} md={12} lg={6} className="padding">
          <Image
              src={require("../../assets/Pokrasova.jpg")}
              fluid
              className="w-100 top"
            />
          </Col>
          <Col xs={12} md={12} lg={6}>
            <p className="text-center fontSize25" style={{ paddingTop: 25 }}>
            Лікар генетик.


              <br /> <b>Стаж роботи за фахом - 15 років.</b>
            </p>
            <p className="text-left" style={{ fontSize: 20, fontWeight: 100 }}>
              <p
                className="text-justify bottom doctorsFont"
                style={{ fontSize: 15.8, fontWeight: 100 }}
              >
                Закінчила Національний медичний університет імені О.О.Богомольця за спеціальністю "лікувальна справа".<br></br><br></br>
Отримала спеціалізацію "акушерство-гінекологія" на базі НМУ ім. О.О.Богомольця.<br></br><br></br>
Освоїла спеціалізацію "генетика медична" на базі Національної медичної академії післядипломної освіти імені Шупика, м.Київ.<br></br><br></br>
Має досвід роботи лікарем акушер-гінекологом та лікарем-генетиком в Житомирському обласному перинатальному центрі.<br></br>
З 2019 р – лікар-генетик медичного центру сімейного здоров’я «ЛіаМед».
              </p>
              <Accordion defaultActiveKey="0" className="border">
                <Card>
                  <Accordion.Toggle as={Card.Header} eventKey="0">
                  КУРСИ СТАЖУВАННЯ, ТЕМАТИЧНОГО УДОСКОНАЛЕННЯ ТА ПІДВИЩЕННЯ КВАЛІФІКАЦІЇ


                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey="0">
                    <Card.Body>
                      <ListGroup style={{ fontSize: 15 }}>
                        <ListGroup.Item>
                          • Постійно бере участь в роботі Міжнародних та Всеукраїнських конгресів, конференцій, семінарів та профільних шкіл з питань акушерства, гінекології та медичної генетики. Останні з них:
                        </ListGroup.Item>
                        <ListGroup.Item>
                          • 30.03.2018. - Конференція у рамках міжнародного науково-практичного форуму репродуктивної медицини "Сучасні підходи до усунення чоловічого фактору безпліддя. Аспекти міждисциплінарної взаємодії репродуктологів і урологів.", м .Київ;
                        </ListGroup.Item>
                        <ListGroup.Item>
                          • 05.04.2018. - Цикл семінарів "Міжнародні та вітчизняні стандарти надання гінекологічної допомоги";
                        </ListGroup.Item>
                        <ListGroup.Item>
                          • 12.04.2018. - Всеукраїнський тренінг-програма для лікарів "Мистецтво лікування";
                        </ListGroup.Item>
                        <ListGroup.Item>
                          • 20.04.2018. - Науково-практична конференція з міжнародною участю "Проблеми спадкової та мультифактоної патології. Орфанні метаболічні захворювання.";
                        </ListGroup.Item>
                        <ListGroup.Item>
                          • 31.05.2018. - Конференція у рамках міжнародного науково-практичного форуму репродуктивної медицини "Генетика в репродукції", м. Київ;
                        </ListGroup.Item>
                        <ListGroup.Item>
                          • 06-08.06.2018. - Міжнародний конгрес з медичної генетики "Генетика та епігенетика рідкісних захворювань", м.Харків;
                        </ListGroup.Item>
                        <ListGroup.Item>
                          • 01-02.11.2018. - Науково-практична конференція з міжнародною участю "Спадкові орфанні захворювання - мультидисциплінарні діалоги";
                        </ListGroup.Item>
                        <ListGroup.Item>
                          • 08.02.2019. - Науково-практична конференція з міжнародною участю "Українська школа міології";
                        </ListGroup.Item>
                        <ListGroup.Item>
                          • 10.04.2019. - Цикл семінарів "Міжнародні та вітчизняні стандарти надання гінекологічної допомоги";
                        </ListGroup.Item>
                        <ListGroup.Item>
                          • 11-12.04.2019. -Науково-практична конференція з міжнародною участю "Останні досягнення генетики людини. Орфанні метаболічні захворювання", м.Київ;
                        </ListGroup.Item>
                        <ListGroup.Item>
                          • 30.05.2019. - "Елементи тератології в клінічній практиці. Тератогенез.";
                        </ListGroup.Item>
                        <ListGroup.Item>
                          • 01-02.06.2019. - Українська Асоціація Репродуктивної Медицини 2019. Міжнародний симпозіуму "Теорія та практика репродукції людини", Буковель;
                        </ListGroup.Item>
                        <ListGroup.Item>
                          • 01.06.2019. - УАРМ "Школа для персоналу клінік ДРТ", м. Івано-Франківськ;
                        </ListGroup.Item>
                        <ListGroup.Item>
                          • 06-07.06.2019. - ІІІ Міжнародний конгрес "Планування сім"ї.Збереження репродуктивного потенціалу країни".Київ.
                        </ListGroup.Item>
                      </ListGroup>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              </Accordion>
            </p>
            {/* <p className="text-center fontSize25">
              Медичний директор центру сімейного здоров’я “ЛІАМЕД”, лікар
              загальної практики вищої категорії .
              <br /> Загальний стаж роботи 27 років.
            </p> */}
            {/* 22.5 25  */}
            {/* <p className="text-center fontSize225">
              Закінчив Вінницький медінститут ім. М.І.Пирогова.У 2018 р. отримав
              сертифікат лікаря-спеціаліста за спеціальністю Організація і
              управління охороною здоров'я.
            </p> */}
          </Col>
        </div>
        <Footer />
      </Container>
    );
  }
}

export default pokrasova;
