import React from "react";

import "../css/Footer.css";

import Pulse from "react-reveal/Pulse";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Col from "react-bootstrap/Col";

class Footer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      viber: false,
      whatsapp: false,
      instagram: false,
    };
  }

  toggleHover = (from) => {
    switch (from) {
      case "viber":
        return this.setState({ viber: !this.state.viber });
      case "whatsapp":
        return this.setState({ whatsapp: !this.state.whatsapp });
      case "instagram":
        return this.setState({ instagram: !this.state.instagram });
      default:
        return true;
    }
  };

  render() {
    return (
      <div
        className="row"
        fluid="true"
        style={{
          backgroundColor: "rgb(241, 218, 228)",
          bottom: 0,
          marginTop: 63,
        }}
      >
        <Col
          xs={{ span: 12, order: 1 }}
          md={{ span: 4, order: 1 }}
          lg={{ span: 4, order: 1 }}
          // style={{ border: "3px solid blue" }}
          className="my-auto d-flex justify-content-between align-items-center"
        >
          <p
            className="text-left width padding-right media_font"
            style={{
              fontWeight: "bold",

              width: "100%",
            }}
          >
            м. Житомир, вул. Перемоги, 53
            <br />
            Premier Hall, 2-й поверх
          </p>
          <p
            className="text-center media_font"
            style={{
              fontWeight: "bold",
              width: "100%",
              height: "50%",
            }}
          >
            +38 067 007-19-19
            <br />
            +38 073 007-19-19
          </p>
        </Col>

        <Col
          xs={{ span: 12, order: 3 }}
          md={{ span: 4, order: 2 }}
          lg={{ span: 4, order: 2 }}
          className="my-auto  d-flex justify-content-center align-items-center"
        >
          <p
            id="copyright "
            style={{ fontWeight: "bold" }}
            className="media_font"
          >
            2024 © LIAMED.COM.UA
          </p>
        </Col>

        <Col
          xs={{ span: 12, order: 2 }}
          md={{ span: 4, order: 3 }}
          lg={{ span: 4, order: 4 }}
          className="my-auto  d-flex justify-content-center align-items-center"
        >
          <p className="p-2 shrink-1">
            <Pulse when={this.state.viber}>
              <a href="tel:+38 067 007-19-19">
                <FontAwesomeIcon
                  onMouseEnter={() => this.toggleHover("viber")}
                  onMouseLeave={() => this.toggleHover("viber")}
                  size="2x"
                  icon={["fab", "viber"]}
                  color={"#665cac"}
                  transform={{ rotate: 20 }}
                />
              </a>
            </Pulse>
          </p>

          <p className="p-2 shrink-2">
            <Pulse when={this.state.whatsapp}>
              <a href="tel:+38 073 007-19-19">
                <FontAwesomeIcon
                  onMouseEnter={() => this.toggleHover("whatsapp")}
                  onMouseLeave={() => this.toggleHover("whatsapp")}
                  size="2x"
                  icon={["fab", "whatsapp"]}
                  color={"#075e54"}
                  transform={{ rotate: 5 }}
                />
              </a>
            </Pulse>
          </p>
          <p className="p-2 shrink-3">
            <Pulse when={this.state.instagram}>
              <a
                href="https://www.instagram.com/liamed.zt/?hl=ru"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon
                  onMouseEnter={() => this.toggleHover("instagram")}
                  onMouseLeave={() => this.toggleHover("instagram")}
                  size="2x"
                  icon={["fab", "instagram"]}
                  color={"#8a3ab9"}
                />
              </a>
            </Pulse>
          </p>
        </Col>
      </div>
    );
  }
}
export default Footer;
