import React from "react";

import Header from "../Header";
import Footer from "../Footer";

import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";

import "../../css/Doctors.css";

// import Card from "react-bootstrap/Card";
// import CardDeck from "react-bootstrap/CardDeck";
// import CardGroup from "react-bootstrap/CardGroup";

import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import ListGroup from "react-bootstrap/ListGroup";

class Shybin extends React.Component {
  render() {
    return (
      <div>
        <Container fluid style={{ marginTop: "5%" }}>
          <Header />
          {/* <NavBar /> */}
          <div className="row">
            <p className="text-left h2 top-h ml-3">Шубін Валерій Дмитрович</p>
          </div>
          <div className="row" style={{ paddingBottom: "10%" }}>
            {/* <Col xs={12} md={12} lg={6} className="padding">
            <Image
              src={require("../../assets/karpyshun_info.png")}
              fluid
              className="w-100 top"
            />
          </Col> */}
            <Col xs={12} md={12} lg={6}>
              <p className="text-center fontSize25" style={{ paddingTop: 25 }}>
                Лікар вищої кваліфікаційної категорії по карідології та терапії.
                <br />
                Загальний стаж роботи лікарем 42 роки.
              </p>
              <p
                className="text-left"
                style={{ fontSize: 20, fontWeight: 100 }}
              >
                <p
                  className="text-justify bottom doctorsFont"
                  style={{ fontSize: 15.8, fontWeight: 100 }}
                >
                  Закінчив Вінницький медінститут ім. М.І. Пирогова по
                  спеціальності “Лікувальна справа”. Отримав другу спеціалізацію
                  за фахом “Кардіологія” на базі Київської медичної академії
                  післядипломної освіти імені П.Л. Шупика МОЗ України. Має
                  досвід роботи лікаря-кардіолога 35 років. З 1983 р. по 1984 р.
                  працював на посаді завідуючого приймально-діагностичним
                  відділенням ЦМЛ №1. З 1984 року – лікар-кардіолог в
                  кардіологічному відділенні для хворих інфарктом міокарду ЦМЛ
                  №1. З 1990 р. по 1995 р. працював на посаді
                  лікаря-анестезіолога-реаніматолога палат інтенсивної терапії
                  кардіологічного відділення для хворих інфарктом міокарду ТМО
                  №1 м.Житомира. Постійно бере участь у міжнародних та
                  українських науково-практичних конференціях, форумах та
                  семінарах, зокрема:
                </p>
                <Accordion defaultActiveKey="1" className="border">
                  <Card>
                    <Accordion.Toggle as={Card.Header} eventKey="0">
                      Заслуги
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="0">
                      <Card.Body>
                        <ListGroup style={{ fontSize: 15 }}>
                          <ListGroup.Item>
                            • Науково-практична конференція Українського
                            товариства з атеросклерозу "Діагностика,
                            профілактика і лікування атеросклерозу та IXC:
                            сучасні підходи та новітні досягнення", м. Київ,
                            2018р.
                          </ListGroup.Item>
                          <ListGroup.Item>
                            • Ексклюзивний курс для лікарів приватної медицини
                            Мистецтво лікування "Артеріальна гіпертензія—червона
                            мітка", м. Київ, 2018р.
                          </ListGroup.Item>
                          <ListGroup.Item>
                            • Науково-практична конференція "Імплементація
                            міжнародних рекомендацій та протоколів з кардіології
                            в роботу практичного лікаря", м. Житомир, 2017р.
                          </ListGroup.Item>
                          <ListGroup.Item>
                            • Науково-практичної конференції "Щоденна практика
                            педіатра:що зараз актуально?", м. Київ, 2016р.
                          </ListGroup.Item>
                          <ListGroup.Item>
                            • Науково-практична конференція в рамках
                            Всеукраїнської соціальної програми "Хронічні
                            захворювання: профілактика та контроль", м. Вінниця,
                            2013р.
                          </ListGroup.Item>
                        </ListGroup>
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                </Accordion>
              </p>
            </Col>
          </div>
        </Container>
        <Footer />
      </div>
    );
  }
}

export default Shybin;
