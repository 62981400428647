import React from "react";

import "../../css/Prices.css";

import Header from "../Header";
import Footer from "../Footer";

import Actions from "./tables/doctors_actions";
import Consultations from "./tables/doctors_consultation";
import Studies from "./tables/studies";

import Image from "react-bootstrap/Image";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";

class Prices extends React.Component {
  render() {
    return (
      <Container fluid style={{ marginTop: "6%" }}>
        <Header />
        <div className="paddingTop margin-left">
          <p>Послуги та ціни</p>
        </div>
        <div className="row">
          <Col xs={12} md={12} lg={6}>
            <Image
              src={require("../../assets/prices_services.jpg")}
              fluid
              className="w-100 margin-left"
            />
            <div className="">
              <p className="text-justify">
                Ціни вказані на 08.09.2024р
                <br />
                <br /> Вартість послуги в день звернення та розміщеної на сайті
                інформації можуть відрізнятися.
                <br />
                Для уточнення інформації звертайтесь за телефонами:
                <br />
                <br /> +38 067 007-19-19 <br />
                +38 073 007-19-19
              </p>
            </div>
          </Col>
          <Col xs={12} md={12} lg={6}>
            <Accordion className="marginBottom" defaultActiveKey="0">
              <Card>
                <Accordion.Toggle as={Card.Header} eventKey="0">
                  Консультації лікарів
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="0">
                  <Card.Body>
                    <Consultations />
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Accordion.Toggle as={Card.Header} eventKey="1">
                  Лікарські процедури і маніпуляції
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="1">
                  <Card.Body>
                    <Actions />
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Accordion.Toggle as={Card.Header} eventKey="2">
                  Інструментальні та лабораторні дослідження
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="2">
                  <Card.Body>
                    <Studies />
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            </Accordion>
          </Col>
        </div>
        {/* <Col xs={12} md={12} lg={4}>

          </Col> */}
        {/* </Container> */}
        <Footer />
      </Container>
    );
  }
}

export default Prices;
