import React from "react";

import Header from "../Header";
import Footer from "../Footer";

import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";

import "../../css/Doctors.css";

import Image from "react-bootstrap/Image";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import ListGroup from "react-bootstrap/ListGroup";

class Popovska extends React.Component {
  render() {
    return (
      <Container fluid style={{ marginTop: "5%" }}>
        <Header />
        {/* <NavBar /> */}
        <div className="row">
          <p className="text-left h2 top-h ml-3">Поповська Алла Анатоліївна</p>
        </div>
        <div className="row">
          <Col xs={12} md={12} lg={6} className="padding">
            <Image
              src={require("../../assets/popovska_info.png")}
              fluid
              className="w-100 top"
            />
          </Col>
          <Col xs={12} md={12} lg={6}>
            <p className="text-center fontSize25" style={{ paddingTop: 25 }}>
              Лікар акушер-гінеколог 1 кваліфікаційної категорії, лікар УЗД.
              <br />
              Загальний лікарський стаж — 11 років.
            </p>
            <p className="text-left" style={{ fontSize: 20, fontWeight: 100 }}>
              <p
                className="text-justify bottom doctorsFont"
                style={{ fontSize: 15.8, fontWeight: 100 }}
              >
                Закінчила Вінницький національний медичний університет ім. М. І.
                Пирогова в 2008році. Пройшла інтернатуру за фахом “Акушерство та
                гінекологія” на базі ВНМУ ім. М. І. Пирогова з 2008 по 2011рр.
                Має досвід роботи завідуючою відділенням акушерства і
                гінекології та пологовим відділенням у центральній районній
                лікарні смт Ружин, досвід роботи районним акушер гінекологом.
                Пройшла курси первинної спеціалізації з УЗД-діагностики на базі
                НМАПО ім. П. Л. Шупика в 2015 р., і являється сертифікованим
                лікарем УЗД. Постійна учасниця конференцій, з’їздів та пленумів
                з акушерства та гінекології, УЗД-діагностики, а також курсів з
                тематичного удосконалення, семінарів тощо. Зокрема:
              </p>
              <Accordion defaultActiveKey="1" className="border">
                <Card>
                  <Accordion.Toggle as={Card.Header} eventKey="0">
                    Заслуги
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey="0">
                    <Card.Body>
                      <ListGroup style={{ fontSize: 15 }}>
                        <ListGroup.Item>
                          • Місячний цикл тематичного удосконалення: “Оцінка
                          стану фетоплацентарного комплексу при вагітності та
                          пологах: комплексна УЗД з використанням 3D та 4D
                          технологій” м. Житомир 2019рік
                        </ListGroup.Item>
                        <ListGroup.Item>
                          • Навчальний цикл семінарів “Здоров’я жінки в 21
                          столітті” м. Вінниця 2018рік
                        </ListGroup.Item>
                        <ListGroup.Item>
                          • Виїзний місячний курс тематичного удосконалення
                          “Скринінгові програми в перинатальній медицині” від
                          ХМАПО
                        </ListGroup.Item>
                        <ListGroup.Item>
                          • Навчальний цикл семінарів в режимі телемосту Вінниця
                          — Дніпропетровськ — Київ “Здоров’я жінки в 21
                          столітті” у 2017 році
                        </ListGroup.Item>
                        <ListGroup.Item>
                          • Виїзний місячний курс тематичного удосконалення
                          “Ендокринологія в акушерстві та гінекології” від ХМАПО
                          в м. Житомир -- 2017рік
                        </ListGroup.Item>
                        <ListGroup.Item>
                          • Семінар-практикум, практичний симуляційний
                          майстер-клас “Мультидисциплінарний підхід у
                          профілактиці та боротьбі з післяпологовими
                          кровотечами” -- м. Житомир 2016рік
                        </ListGroup.Item>
                        <ListGroup.Item>
                          • 3-й національний конгрес з міжнародною участю
                          “Радіологя в Україні” -- м. Київ 2015рік;
                        </ListGroup.Item>
                        <ListGroup.Item>
                          • 4-й міжнародний медичний конгрес “Впровадження
                          сучасних досягнень медичної науки в практику охорони
                          здоров’я жінки України” -- м. Київ 2015рік
                        </ListGroup.Item>
                        <ListGroup.Item>
                          • Курси стажування на робочому місці на базі Обласного
                          перинатального центру м. Житомир 2009, 2010 рр
                        </ListGroup.Item>
                        <ListGroup.Item>
                          • Конференція “Імунологічна безпека гемотрансфузійної
                          терапії” -- м. Житомир 2010 рік.
                        </ListGroup.Item>
                      </ListGroup>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              </Accordion>
            </p>
            {/* <p className="text-center fontSize25">
              Лікар акушер-гінеколог 2 кваліфікаційної категорії, лікар УЗД.
              <br />
              Загальний лікарський стаж — 11 років.
            </p> */}
            {/* 22.5 25  */}
            {/* <p className="text-center fontSize225">
              Закінчив Вінницький медінститут ім. М.І.Пирогова.У 2018 р. отримав
              сертифікат лікаря-спеціаліста за спеціальністю Організація і
              управління охороною здоров'я.
            </p> */}
          </Col>
        </div>
        <Footer />
      </Container>
    );
  }
}

export default Popovska;
